import React from "react";
import DatePicker from "react-datepicker";
import {labelFormatter} from "../../../../common/helpers/utils/Formatter";
import localisable from "../../../../common/constants/localisable";
import {
  capitalize,
  camelToSnakeCase,
} from "../../../../common/helpers/utils/DataHelper";
import BellCurve from "./BellCurve/BellCurve";

const MetricSection = ({
  metrics,
  metric,
  isMainSection,
  handleChange,
  handleBlur,
  generateGraph,
  handleRemove,
  formData,
  setFormData,
  graphData,
  platform,
  activeSubPlatform,
  activeCategory,
  activeType,
  use_platform_metric,
  setGraphStates,
  isgraphvisible,
  setMetrics,
  isEditMode,
  showSubmitButton,
  typeData,
  isGraphLoading,
  Spinner,
  updateScores,
  getCounts,
  showSpinner,
  pointersLoading,
}) => {
  const selectedMetrics = metrics.map((m) => m.name);
  const availableMetrics = typeData[activeType]?.metrics.filter(
    (option) =>
      !selectedMetrics.includes(option.metric_name) ||
      option.metric_name === metric.name
  );

  const handleMetricChange = (id, selectedMetric) => {
    const oldMetricName = metrics.find((metric) => metric.id === id)?.name;

    // Remove the old metric data from graphStates
    setGraphStates((prevGraphStates) => {
      const updatedGraphStates = {...prevGraphStates};
      if (oldMetricName && oldMetricName in updatedGraphStates) {
        delete updatedGraphStates[oldMetricName];
      }
      return updatedGraphStates;
    });
    const metricDetails =
      typeData[activeType]?.metrics?.find(
        (metric) => metric.metric_name === selectedMetric
      ) || {};

    const is_increasing = metricDetails.is_increasing;
    setMetrics((prevMetrics) =>
      prevMetrics.map((metric) =>
        metric.id === id
          ? {
              ...metric,
              name: selectedMetric,
              is_increasing_score: is_increasing,
            }
          : metric
      )
    );
  };

  const handleDateChange = (id, name, date) => {
    setMetrics((prevMetrics) =>
      prevMetrics.map((metric) =>
        metric.id === id
          ? {
              ...metric,
              [name]: date, // Dynamically update startDate or endDate
            }
          : metric
      )
    );
  };

  return (
    <div className="row mt-3 mb-2" key={metric.id}>
      <div className="col-lg-4 ui-sortable border-end border-light">
        <div className="row pb-3 d-flex align-items-center">
          <div className="col-lg-4">
            <span className="m-0">
              {isMainSection ? "KPI" : `Additional KPI`}
            </span>
          </div>
          <div className="col-lg-4">
            {/* Metric Field Dropdown/Input */}
            {typeData[activeType]?.metrics.length > 0 ? (
              <select
                className="form-select form-select-sm"
                style={{
                  cursor:
                    (!isEditMode && !showSubmitButton) || use_platform_metric
                      ? ""
                      : "pointer",
                }}
                name="metric_field"
                value={metric.name}
                onChange={(e) => {
                  handleMetricChange(metric.id, e.target.value);
                  handleChange(
                    e,
                    platform,
                    activeSubPlatform,
                    activeCategory,
                    activeType,
                    metric.name
                  );
                }}
                required
                disabled={
                  (!isEditMode && !showSubmitButton) || use_platform_metric
                }>
                <option value="">Select Metric Field</option>
                {availableMetrics.map((option, idx) => (
                  <option key={idx} value={option.metric_name}>
                    {capitalize(
                      labelFormatter(camelToSnakeCase(option.metric_name)),
                      true
                    )}
                  </option>
                ))}
              </select>
            ) : (
              <input
                className="form-control form-control-sm"
                type="text"
                name="metric_field"
                value={metric.name}
                onChange={(e) =>
                  handleChange(
                    e,
                    platform,
                    activeSubPlatform,
                    activeCategory,
                    activeType
                  )
                }
                placeholder="No metric fields available"
                disabled
              />
            )}
          </div>
        </div>
        {["Excellent", "Good", "Average", "Low"].map((label, idx) => (
          <div className="row pb-3 d-flex align-items-center" key={idx}>
            <div className="col-lg-4">
              <span className="m-0">{label} Score</span>
            </div>
            <div className="col-lg-4">
              <input
                type="text"
                id={`${label.toLowerCase()}_score`}
                className="form-control form-control-sm editing-input"
                name={`${label.toLowerCase()}_score`}
                value={
                  formData[platform]?.[activeSubPlatform]?.[activeCategory]?.[
                    activeType
                  ]?.[metric.name]?.[`${label.toLowerCase()}_score`] ?? ""
                }
                onChange={(e) =>
                  handleChange(
                    e,
                    platform,
                    activeSubPlatform,
                    activeCategory,
                    activeType,
                    metric.name
                  )
                }
                onBlur={(e) => {
                  getCounts(metric.name, `${label.toLowerCase()}_score`);
                  handleBlur(
                    e,
                    platform,
                    activeSubPlatform,
                    activeCategory,
                    activeType,
                    isgraphvisible,
                    metric.name,
                    !metric.is_increasing_score
                  );
                }}
                disabled={!isEditMode || (isEditMode && use_platform_metric)}
              />
            </div>
          </div>
        ))}
        <div className="row pb-2 d-flex align-items-center">
          <p className="mb-0">
            Last Updated By: {metric.updated_by_user || localisable.notSetYet}
          </p>
        </div>
        {/* Remove Button */}
        <div className="row pb-2 d-flex align-items-center">
          <p className="mb-0">
            {isEditMode && (
              <button
                className="btn btn-danger btn-sm d-flex ms-1"
                title="Remove metric"
                onClick={(e) => {
                  e.preventDefault();
                  handleRemove(metric.id, metric.name); // Remove the entire metric
                }}>
                <i className="bi bi-trash me-1"></i>
              </button>
            )}
          </p>
        </div>
      </div>
      <div className="col-lg-8">
        <div className="row d-flex align-items-center">
          <div className="col-auto">
            <DatePicker
              className="form-control editing-input border-secondary text-white px-2"
              selected={metric.startDate ? new Date(metric.startDate) : null}
              onChange={(date) =>
                handleDateChange(metric.id, "startDate", date)
              }
              placeholderText="Start Date"
              dateFormat="MMM dd, yyyy"
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              maxDate={metric.endDate ? new Date(metric.endDate) : new Date()}
              disabled={!isEditMode || use_platform_metric}
            />
          </div>
          <div className="col-auto">
            <DatePicker
              className="form-control editing-input border-secondary text-white px-2"
              selected={metric.endDate ? new Date(metric.endDate) : null}
              onChange={(date) => handleDateChange(metric.id, "endDate", date)}
              placeholderText="End Date"
              dateFormat="MMM dd, yyyy"
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              minDate={metric.startDate ? new Date(metric.startDate) : null}
              maxDate={new Date()}
              disabled={!isEditMode || use_platform_metric}
            />
          </div>
          <div className="col-auto">
            <button
              className="btn btn-primary btn-sm mb-1"
              title="Generate Graph"
              onClick={(e) => {
                e.preventDefault();
                generateGraph(
                  metric.name,
                  !metric.is_increasing_score,
                  metric.startDate,
                  metric.endDate
                );
              }}
              disabled={!isEditMode || use_platform_metric}>
              Go
            </button>
          </div>
        </div>
        {isGraphLoading ? (
          <Spinner />
        ) : (
          <>
            {/* Input fields for thresholds */}
            {/* Graph */}
            {isgraphvisible ? (
              <div className="col-12 d-flex align-items-center h-100">
                <BellCurve
                  key={showSpinner}
                  data={graphData}
                  kpi={metric.name}
                  formData={formData}
                  setFormData={setFormData}
                  platform={platform}
                  activeSubPlatform={activeSubPlatform}
                  activeCategory={activeCategory}
                  activeType={activeType}
                  metric_name={metric.name}
                  isEditMode={isEditMode}
                  isreverse={!metric.is_increasing_score}
                  updateScores={updateScores}
                  getCounts={getCounts}
                  showSpinner={showSpinner}
                  pointersLoading={pointersLoading}
                  className="ms-auto"
                />
              </div>
            ) : (
              <div className="col-12 d-flex justify-content-center align-items-center h-100">
                <span className="text-center">
                  Not enough data available to generate Performance thresholds
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MetricSection;
