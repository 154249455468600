import React, {useState} from "react";
import Spinner from "../../../components/spinner";
import axios from "axios";
import {Modal} from "react-bootstrap";
import ENDPOINTS from "../../../common/endpoints";
import {encryptPassword} from "../../../common/helpers/utils/PasswordHelper";
import PasswordValidation from "../../../common/components/PasswordValidation";

const Users = ({
  logged_user,
  fetchClientData,
  fetchUserData,
  userData,
  clientData,
  setUserToEdit,
  userToEdit,
  clientToEdit,
  setNewUser,
  newUser,
  setIsLoading,
  isLoading,
  setShowSuccessMessage,
  setShowErrorMessage,
  showErrorMessage,
  error,
  setError,
  setMessage,
  Message,
  setFormValid,
  formValid,
  clearFormData,
  handleChange,
  handleFormChange,
  fetchUser,
}) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [showEditUserModal, setshowEditUserModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);


  const addUser = (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem("token");
    const formData = new FormData(e.target);
    const body = Object.fromEntries(formData.entries());
    const encryptedPassword = encryptPassword(body.password);
    body.password = encryptedPassword;

    setIsLoading(true);
    axios
      .post(ENDPOINTS.ONBOARDING.add_user, body, {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // Refresh client data after adding the client
        fetchClientData();
        fetchUserData();
        handleCloseUserModal();
        setTimeout(() => {
          setIsLoading(false);
          setShowSuccessMessage(true);
          setMessage(response.data.message);

          // Hide toast after 3 seconds
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
        }, 3000);
      })
      .catch((error) => {
        clearFormData("addUser");
        setIsLoading(false);
        setShowErrorMessage(true);
        setMessage(error.response.data.error);

        // Hide toast after 3 seconds
        // setTimeout(() => {
        //   setShowErrorMessage(false);
        // }, 3000);
        // console.error("Error adding user:", error);
      })
      .finally(() => {
        setFormValid(false);
      });
  };

  const editUser = (e, toggleIsActive) => {
    e.preventDefault();
    setIsLoading(true);
    const authToken = localStorage.getItem("token");
    let updatedIs_Active = clientToEdit.is_active;
    if (toggleIsActive !== undefined) {
      updatedIs_Active = !toggleIsActive;
    }
    const body = {
      user_id: userToEdit.id,
      role: userToEdit.role,
      name: userToEdit.name,
      is_active: updatedIs_Active,
    };
    // console.log(body)
    axios
      .put(ENDPOINTS.ONBOARDING.update_user, body, {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // get the updated user
        fetchUser();
        // Refresh client data after adding the client
        fetchClientData();

        // Close the modal
        const modal = document.getElementById("addclient");
        if (modal) {
          modal.classList.remove("show");
          modal.setAttribute("aria-hidden", "true");
          modal.style.display = "none";
          const modalBackdrop =
            document.getElementsByClassName("modal-backdrop")[0];
          if (modalBackdrop) {
            modalBackdrop.remove();
          }
        }

        // Reset the form fields
        setNewUser({name: "", email: "", password: "", role: "", client: ""});
        fetchUserData();
        setTimeout(() => {
          setIsLoading(false);
          setShowSuccessMessage(true);
          setMessage(response.data.message);

          // Hide toast after 3 seconds
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
        }, 3000);
      })
      .catch((error) => {
        setNewUser({name: "", email: "", password: "", role: "", client: ""});
        setIsLoading(false);
        setShowErrorMessage(true);
        setMessage(error.response.data.error);

        // Hide toast after 3 seconds
        setTimeout(() => {
          setShowErrorMessage(false);
        }, 3000);
        // console.error("Error adding user:", error);
      });
  };

  const setEditUser = (userId, userName, userRole, is_active) => {
    setUserToEdit({
      id: userId,
      name: userName,
      role: userRole,
      is_active: is_active,
    });
  };

  const changePassword = (event) => {
    event.preventDefault();
    const authToken = localStorage.getItem("token");
    const encryptedNewPassword = encryptPassword(newPassword);
    const body = {
      user_id: userToEdit.id,
      updated_password: encryptedNewPassword,
    };
    setIsLoading(true);
    axios
      .put(ENDPOINTS.ONBOARDING.update_user_password, body, {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        setConfirmPassword("");
        setNewPassword("");
        setShowSuccessMessage(true);
        setMessage(response.data.message);
        // Hide toast after 3 seconds
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
      })
      .catch((error) => {
        setIsLoading(false);
        setShowErrorMessage(true);
        setMessage(error.response.data.error);

        // Hide toast after 3 seconds
        setTimeout(() => {
          setShowErrorMessage(false);
        }, 3000);
        // console.error("Error adding user:", error);
      })
      .finally(() => {
        handleclosepasswordModal();
      });
  };

  const handleCloseUserModal = () => {
    clearFormData("addUser");
    setShowUserModal(false);
  };

  const handleCloseEditUserModal = () => {
    clearFormData("editUser");
    setshowEditUserModal(false);
  };

  const handleShowUserModal = () => setShowUserModal(true);

  const handleShowEditUserModal = (user) => {
    setEditUser(user.id, user.name, user.role, user.is_active);
    setshowEditUserModal(true);
  };

  const handleclosepasswordModal = () => {
    setConfirmPassword("");
    setNewPassword("");
    setShowPasswordModal(false);
  };

  const handleshowpasswordModal = (user) => {
    setEditUser(user.id, user.name, user.role, user.is_active);
    setShowPasswordModal(true);
  };

  return (
    <div className="text-center mx-3">
      <div className="row">
        <div className="col-md-6 col-6 d-flex justify-content-start my-2">
          <h4>Users</h4>
        </div>
        <div className="col-md-6 col-6 d-flex justify-content-end my-2">
          <button className="btn btn-primary" onClick={handleShowUserModal}>
            <i className="bi bi-person-fill-add mx-1"></i>
            Add User
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center mt-4 pt-2">
          <Spinner />
        </div>
      ) : (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-11">
              <table className="table table-hover table-bordered border-secondary border-2 custom-table">
                <thead>
                  <tr>
                    <th>Sr. No</th>
                    {logged_user.user_role === "super_admin" && <th>Client</th>}
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.map((user, index) => (
                    <tr key={user.id}>
                      <td>{index + 1}</td>
                      {logged_user.user_role === "super_admin" && (
                        <td>{user.client}</td>
                      )}
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>
                        {user.role === "client_admin" && "Client Admin"}
                        {user.role === "super_admin" && "Super Admin"}
                        {user.role === "user" && "User"}
                      </td>
                      <td>{user.is_active ? "Active" : "Inactive"}</td>
                      <td className="d-flex">
                        <button
                          className="btn btn-secondary me-2"
                          title="Edit"
                          onClick={() => handleShowEditUserModal(user)}>
                          <i className="bi bi-pencil-square"></i>{" "}
                        </button>
                        <button
                          className="btn btn-secondary me-2"
                          title="Password Reset"
                          onClick={() => handleshowpasswordModal(user)}>
                          <i className="bi bi-key"></i>{" "}
                        </button>
                        {user.is_active ? (
                          <button
                            className="btn btn-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#userstatus"
                            title="Deactivate"
                            onClick={() =>
                              setEditUser(
                                user.id,
                                user.name,
                                user.role,
                                user.is_active
                              )
                            }>
                            <i className="bi bi-exclamation-circle-fill me-1"></i>
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#userstatus"
                            title="Activate"
                            onClick={() =>
                              setEditUser(
                                user.id,
                                user.name,
                                user.role,
                                user.is_active
                              )
                            }>
                            <i className="bi bi-check-circle-fill me-1"></i>
                          </button>
                        )}
                        {/* <button
                    className="btn p-0 border-none text-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteuser"
                    onClick={() =>
                      SetDeleteUser(user.id, user.name)
                    }>
                    <i className="bi bi-trash3-fill mx-2"></i>
                  </button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {/* AddUser Modal */}
      <Modal show={showUserModal} onHide={handleCloseUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <form onSubmit={addUser} onChange={handleFormChange}>
          <div className="modal-body">
            <div className="mb-3">
              <label
                htmlFor="name"
                className="form-label d-flex justiy-content-start text-dark">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={newUser.name}
                onChange={(e) => handleChange(e, "user")}
                required
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="email"
                className="form-label d-flex justiy-content-start text-dark">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={newUser.email}
                onChange={(e) => handleChange(e, "user")}
                required
              />
              {error.email && (
                <div className="text-danger" role="alert">
                  {error.email}
                </div>
              )}
            </div>
            <PasswordValidation
                  password={newPassword}
                  setPassword={setNewPassword}
                  confirmPassword={confirmPassword}
                  setConfirmPassword={setConfirmPassword}
                  setIsPasswordValid={setIsPasswordValid}
                  isPasswordValid={isPasswordValid}
              />
            <div className="mb-3">
              <label
                htmlFor="role"
                className="form-label d-flex justiy-content-start text-dark">
                Role
              </label>
              <select
                className="form-select"
                id="role"
                name="role"
                value={newUser.role}
                onChange={(e) => handleChange(e, "user")}
                required>
                <option value="user">User</option>
                <option value="client_admin">Client Admin</option>
                {logged_user.user_role === "super_admin" && (
                  <option value="super_admin">Super Admin</option>
                )}
              </select>
            </div>
            <div className="mb-3">
              <label
                htmlFor="client"
                className="form-label d-flex justiy-content-start text-dark">
                Client
              </label>

              {logged_user.user_role === "super_admin" ? (
                <>
                  <select
                    className="form-select"
                    id="client"
                    name="client"
                    value={newUser.client}
                    onChange={(e) => handleChange(e, "user")}
                    required
                    disabled={logged_user.user_role !== "super_admin"}>
                    <option value="">Select Client</option>
                    {clientData.map((client, index) => (
                      <option key={index} value={client.name}>
                        {client.name}
                      </option>
                    ))}
                  </select>{" "}
                </>
              ) : (
                <input
                  className="form-control"
                  id="client"
                  name="client"
                  value={logged_user.client_name}
                  onChange={(e) => handleChange(e, "user")}
                  disabled={true}
                  required
                />
              )}
              {error.password && <div className="text-danger mt-2">{error.password}</div>}
            </div>
          </div>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={handleCloseUserModal}>
              Close
            </button>
            {formValid && (
              <button type="submit" className="btn btn-primary"  disabled={newPassword !== confirmPassword || !isPasswordValid}>
                Save
              </button>
            )}
            {!formValid && (
              <button
                type="button"
                className="btn btn-primary disabled"
                disabled={newPassword !== confirmPassword || !isPasswordValid}>
                Save
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
      {/* EditUser Modal */}
      <Modal show={showEditUserModal} onHide={handleCloseEditUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <form onSubmit={editUser} onChange={handleFormChange}>
          <div className="modal-body">
            <div className="mb-3">
              <label
                htmlFor="name"
                className="form-label d-flex justiy-content-start text-dark">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={userToEdit.name}
                onChange={(e) => handleChange(e, "edit_user")}
                required
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="user_role"
                className="form-label d-flex justiy-content-start text-dark">
                Role
              </label>
              <select
                className="form-select"
                id="role"
                name="role"
                value={userToEdit.role}
                onChange={(e) => handleChange(e, "edit_user")}
                required>
                <option value="client_admin">Client Admin</option>
                <option value="user">User</option>
                {logged_user.user_role === "super_admin" && (
                  <option value="super_admin">Super Admin</option>
                )}
              </select>
            </div>
          </div>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={handleCloseEditUserModal}>
              Close
            </button>
            <button
              type={formValid ? "submit" : "button"}
              className={`btn btn-primary ${!formValid && "disabled"}`}
              disabled={!formValid}>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {/* Password Reset Modal */}
      <Modal
        show={showPasswordModal}
        onHide={handleclosepasswordModal}
        aria-labelledby="passwordresetLabel">
        <Modal.Header closeButton>
          <Modal.Title id="passwordresetLabel">Password Reset</Modal.Title>
        </Modal.Header>
        <form onSubmit={changePassword} onChange={handleFormChange}>
          <div className="modal-body">
          <PasswordValidation
                  password={newPassword}
                  setPassword={setNewPassword}
                  confirmPassword={confirmPassword}
                  setConfirmPassword={setConfirmPassword}
                  setIsPasswordValid={setIsPasswordValid}
                  isPasswordValid={isPasswordValid}
              />
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={handleclosepasswordModal}>
              Close
            </button>

            <button
              type="submit"
              className={`btn btn-primary ${
                newPassword !== confirmPassword ? "disabled" : ""
              }`}
              disabled={newPassword !== confirmPassword || !isPasswordValid}>
              Reset
            </button>
          </div>
        </form>
      </Modal>
      {/* UserStatus Modal */}
      <div
        className="modal fade"
        id="userstatus"
        tabIndex="-1"
        aria-labelledby="userstatusLabel"
        aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content text-dark">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title" id="userstatusLabel">
                {userToEdit.is_active
                  ? `Are you sure you want to Deactivate ${userToEdit.name}`
                  : `Are you sure you want to Activate ${userToEdit.name}`}
              </h5>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={(e) => editUser(e, userToEdit.is_active)}>
                Yes
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close">
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
