import * as d3 from "d3";

export const createLegend = (svg, data, width, margin, isreverse, showSpinner, pointersLoading) => {
    // Add a legend for the percentile ranges
    const legendX = isreverse ? margin.left - 120 : width - margin.right;
    const legendY = margin.top - 10;

    // Append a group for the legend
    const legendGroup = svg
      .append("g")
      .attr("transform", `translate(${legendX}, ${legendY})`);

    // Add CSS for spinner animation
    const styleElement = document.createElement("style");
    styleElement.textContent = `
      @keyframes spin {
        from {
          stroke-dashoffset: 0;
        }
        to {
          stroke-dashoffset: -15;
        }
      }
    `;
    document.head.appendChild(styleElement);

    // Define the legend colors and labels based on percentile ranges
    const legendData = [
      { color: "#cf0000", label: "Very Low", count: data.asset_counts.very_low },
      { color: "#ff6344", label: "Low", count: data.asset_counts.low },
      { color: "#FFFF00", label: "Average", count: data.asset_counts.average },
      { color: "#b2ff6b", label: "Good", count: data.asset_counts.good },
      { color: "#00a318", label: "Excellent", count: data.asset_counts.excellent },
    ];

    const formatNumber = (d) => (d >= 1_000_000 ? d3.format(".2s")(d).replace("G", "B") : d);

    // Create a container for the legend
    const table = legendGroup.append("g")
      .attr("transform", "translate(0, 20)");

    // Add Total Assets row
    const totalAssetsRow = table.append("g")
      .attr("transform", "translate(0, 0)");

    // Empty first column
    totalAssetsRow
      .append("text")
      .attr("x", 0)
      .attr("y", 0)
      .style("font-size", "12px")
      .style("fill", "white")
      .text(""); // Empty first column

    // "Total Assets" in second column
    totalAssetsRow
      .append("text")
      .attr("x", 25)
      .attr("y", 0)
      .style("font-size", "12px")
      .style("fill", "white")
      .text("Total Assets");

    // Colon in third column
    totalAssetsRow
      .append("text")
      .attr("x", 95)
      .attr("y", 0)
      .style("font-size", "12px")
      .style("fill", "white")
      .text(":");

    // Total count in fourth column
    totalAssetsRow
      .append("text")
      .attr("x", 105)
      .attr("y", 0)
      .style("font-size", "12px")
      .style("fill", "white")
      .text(formatNumber(data.asset_counts.total));

    // Add rows for each color/label/count in the legend
    legendData.forEach((d, i) => {
      const row = table.append("g")
        .attr("transform", `translate(0, ${20 * (i + 1)})`);

      // Add color box (in a centered fashion within the first column)
      row
        .append("rect")
        .attr("x", 0)
        .attr("y", -8)
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", d.color);

      // Add label
      row
        .append("text")
        .attr("x", 25)
        .attr("y", 4)
        .style("font-size", "12px")
        .style("fill", "white")
        .text(d.label);

      // Add colon
      row
        .append("text")
        .attr("x", 95)
        .attr("y", 4)
        .style("font-size", "12px")
        .style("fill", "white")
        .text(":");

      // Add count and spinner logic
      const countCell = row.append("g")
        .attr("transform", "translate(105, 4)");

      // Check if spinner should be shown for this label
      const normalizedLabel = d.label.toLowerCase().replace(/\s+/g, "_");
      const shouldShowSpinner = showSpinner && Object.values(pointersLoading).includes(normalizedLabel);

      // Add the count text (Only show count if no spinner)
      countCell
        .append("text")
        .attr("x", 0)
        .attr("y", 0)
        .style("font-size", "12px")
        .style("fill", "white")
        .text(shouldShowSpinner ? "" : formatNumber(d.count)); // Show count only if no spinner

      // Add spinner if applicable
      if (shouldShowSpinner) {
        countCell
          .append("circle")
          .attr("cx", 8) // Position spinner slightly to the right of the count
          .attr("cy", -2)  // Align vertically with the text
          .attr("r", 6)   // Radius of the spinner
          .attr("fill", "none")
          .attr("stroke", "white")
          .attr("stroke-width", 2)
          .attr("stroke-dasharray", "10 5") // Dashed stroke for spinner effect
          .attr("stroke-dashoffset", 0)
          .style("animation", "spin 1s linear infinite"); // Add CSS animation for spinning
      }
    });
}
