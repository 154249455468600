import JSEncrypt from "jsencrypt";
import PasswordValidator from 'password-validator';
import config from "../../../config.json";

// Initialize RSA encryption with the public key
const publicKey = config.publicKey;

/**
 * Encrypts the password using the RSA public key.
 *
 * @param {string} password - The password to be encrypted.
 * @returns {string} - The encrypted password in base64 format.
 */
export const encryptPassword = (password) => {
  if (!password) {
    throw new Error("Password is required for encryption");
  }

  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);

  // Check if the encryption was successful
  const encrypted = encrypt.encrypt(password);

  if (!encrypted) {
    throw new Error("Encryption failed. Ensure the public key is correct.");
  }

  return encrypted;
};

const passwordSchema = new PasswordValidator();

passwordSchema
  .is().min(8)                                // Minimum length 8
  .is().max(100)                              // Maximum length 100
  .has().uppercase()                          // Must have uppercase letters
  .has().lowercase()                          // Must have lowercase letters
  .has().digits()                             // Must have at least one digit
  .has().symbols()                            // Must have at least one special character
  .has().not().spaces();                      // Should not have spaces

export const validatePassword = (password) => {
  const validationDetails = passwordSchema.validate(password, { details: true });
  return validationDetails.map((error) => ({
    ...error,
    message: error.message.replace("string", "password"), // Replace "string" with "password"
  }));
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
