import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import Spinner from "../components/spinner";
import axios from "axios";
import {getAPIConfig} from "../common/utils";
import ENDPOINTS from "../common/endpoints";
import {validateEmail} from "../common/helpers/utils/PasswordHelper"

const ForgetPasswordModal = ({toggleModal, isModalOpen}) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // Clear the error when the user starts typing
    if (emailError) {
      setEmailError("");
    }
  };

  const handleVerifyEmail = () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setIsLoading(true);
      const requestBody = {
        email: email,
      };
      const configuration = getAPIConfig();
      axios
        .post(ENDPOINTS.AUTH.forgot_password, requestBody, configuration)
        .then((response) => {
          const data = response.data;
          setIsLoading(false);
          setMessage(data.message);
          setTimeout(() => {
            setMessage("");
            toggleModal();
          }, 3000);
        })
        .catch((error) => {
          setIsLoading(false);
          setEmailError(error.response.data?.message);
          //   console.error("Error:", error);
        });
    }
  };

  return (
    <Modal show={isModalOpen} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Forget Password</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={toggleModal}></button>
        </div>
        <div className="modal-body">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center mt-4 pt-2">
              <Spinner />
            </div>
          ) : (
            <form>
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="form-label d-flex justify-content-start text-dark">
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <small className="text-danger">{emailError}</small>
                )}
              </div>
              {message && (
                <div className="alert alert-success mt-3">
                  {message}
                </div>
              )}
            </form>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleModal}>
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleVerifyEmail}>
            Verify Email
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ForgetPasswordModal;
