import React, {useEffect, useState} from "react";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import PerformanceMetric from "./PerformanceSettings/PerformanceMetric";
import whiteLabel from "../../../assets/whitelabelConfig";
import DatePicker from "react-datepicker";
import Spinner from "../../../components/spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Styles/style.css";
import config from "../../../config.json";
import {Modal} from "react-bootstrap";


function Dashboard_API(props) {
  const [SelectedPlatform, setSelectedPlatform] = useState(null);
  const [performanceMatrixOpen, setPerformanceMatrixOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [Message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    media_platform: "",
    token: "",
    description: "",
    performance_report_name: "",
    start_date: "",
    end_date: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const authuri = `${config.serviceUrl}/api_feed/generate_authuri`;
  const submit_access_token = `${config.serviceUrl}/api_feed/submit-access-token`;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const successmessage = searchParams.get("message");
    const errormessage = searchParams.get("error_message");
    if (successmessage) {
      setMessage(successmessage);
      if (successmessage.includes("Failed")) {
        setShowErrorMessage(true);
        setTimeout(() => {
          setShowErrorMessage(false);
        }, 5000);
      } else {
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 5000);
      }
      // Clear the message parameter from the URL
      navigate({search: ""});
    } else if (errormessage) {
      setMessage(errormessage);
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 5000);
      // Clear the message parameter from the URL
      navigate({search: ""});
    }
  }, [location, navigate]);

  const ConnectAPI = (platform) => {
    const authToken = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      media_platform: platform,
    };
    // console.log(body);
    axios
      .post(authuri, body, config)
      .then((response) => {
        const {auth_uri} = response.data;
        window.location.href = auth_uri;
      })
      .catch((error) => {
        // console.error("Error generating authentication URI:", error);
      })
      .finally(() => {
        closeModal();
      });
  };

  const closeModal = () => {
    setIsChecked(false);
    setModalOpen(false);
    clearForm();
    setPerformanceMatrixOpen(false);
  };

  const openModal = (platform, event) => {
    event.stopPropagation();
    setSelectedPlatform(platform);
    setFormData((prevFormData) => ({
      ...prevFormData,
      media_platform: platform,
    }));
    setModalOpen(true);
  };

  const openPerformanceMetric = (platform, event) => {
    setTimeout(() => navigate(`/api/performance-metric/${platform}`), 500);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    if (isFormValid) {
      setIsLoading(true);
      const authToken = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      };
      const body = {
        media_platform: SelectedPlatform,
        token_detail: {
          ttd_auth: formData.token,
          start_date: formData.start_date,
          end_date: formData.end_date,
          performance_report_name: formData.performance_report_name,
          description: formData.description,
        },
      };
      // console.log(body);
      axios
        .post(submit_access_token, body, config)
        .then((response) => {
          setIsLoading(false);
          closeModal();
          setMessage(response.data.message);
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 5000);
        })
        .catch((error) => {
          setIsLoading(false);
          closeModal();
          setMessage(error.response.data.error);
          setShowErrorMessage(true);
          setTimeout(() => {
            setShowErrorMessage(false);
          }, 5000);
          // console.error(error);
        });
    } else {
      // Optionally, you can provide user feedback here
      alert("Please fill in all required fields.");
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleDateChange = (date, field) => {
    if (date) {
      const formattedDate = `${date.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      })}`;
      setFormData((prevState) => ({
        ...prevState,
        [field]: formattedDate,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [field]: "",
      }));
    }
  };

  useEffect(() => {
    const {token, description, start_date, end_date} = formData;
    if (token && description && start_date && end_date) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  const clearForm = () => {
    setFormData({
      media_platform: "",
      token: "",
      performance_report_name: "",
      description: "",
      start_date: "",
      end_date: "",
    });
  };

  return (
    <div
      className="container-fluid position-relative z-index-1 pb-5"
      onScroll={props.handleScroll}
      style={{top: "58px"}}>
      <div className="row text-white mt-4 d-flex align-items-center justify-content-center border-bottom ">
        {(showSuccessMessage || showErrorMessage) && (
          <div className="row">
          <div className={`alert ${showErrorMessage ? "alert-danger" : "alert-success"} role="alert" `}>
            {Message}
          </div>
          </div>
        )}
        <div
          className="col-md-4 d-flex justify-content-center">
          <h6 className="mb-2">Network</h6>
        </div>
        <div className="col-md-2 d-flex justify-content-center">
          <h6 className="mb-2 mx-2 align-items-center">Performance Metric</h6>
        </div>
        <div className="col-md-6 d-flex justify-content-center">
          <h6 className="mb-2 mx-2">Description</h6>
        </div>
      </div>

      {/* Meta */}
      <div className="row text-white py-2 d-flex align-items-center border-bottom">
        <div className="col-md-4 d-flex align-items-center justify-content-center">
          <button
            className="btn btn-primary api-button d-flex align-items-center"
            onClick={(event) => openModal("Meta", event)}
            style={{width: "225px"}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-meta me-2 fs-5"
              style={{width: "20px", height: "20px"}}
              viewBox="0 0 16 16">
              <path
                fillRule="evenodd"
                d="M8.217 5.243C9.145 3.988 10.171 3 11.483 3 13.96 3 16 6.153 16.001 9.907c0 2.29-.986 3.725-2.757 3.725-1.543 0-2.395-.866-3.924-3.424l-.667-1.123-.118-.197a55 55 0 0 0-.53-.877l-1.178 2.08c-1.673 2.925-2.615 3.541-3.923 3.541C1.086 13.632 0 12.217 0 9.973 0 6.388 1.995 3 4.598 3q.477-.001.924.122c.31.086.611.22.913.407.577.359 1.154.915 1.782 1.714m1.516 2.224q-.378-.614-.727-1.133L9 6.326c.845-1.305 1.543-1.954 2.372-1.954 1.723 0 3.102 2.537 3.102 5.653 0 1.188-.39 1.877-1.195 1.877-.773 0-1.142-.51-2.61-2.87zM4.846 4.756c.725.1 1.385.634 2.34 2.001A212 212 0 0 0 5.551 9.3c-1.357 2.126-1.826 2.603-2.581 2.603-.777 0-1.24-.682-1.24-1.9 0-2.602 1.298-5.264 2.846-5.264q.136 0 .27.018Z"
              />
            </svg>
            Sign in with Meta
          </button>
        </div>
        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <button
            className="btn btn-secondary"
            onClick={(event) => openPerformanceMetric("Meta", event)}>
            <i className="bi bi-gear"></i>
          </button>
        </div>
        <div className="col-md-6">
          <p>
            Please ensure that you have an ad account associated with the
            Facebook profile that you're logging in with.
          </p>
        </div>
      </div>
      {/* Google */}
      <div className="row text-white py-2 d-flex align-items-center border-bottom">
        <div className="col-md-4 d-flex align-items-center justify-content-center">
          <button
            className="btn api-button btn-light d-flex align-items-center"
            onClick={(event) => openModal("Google", event)}
            style={{width: "225px"}}>
            <img
              className="me-2"
              src="https://cdn.iconscout.com/icon/free/png-256/free-google-160-189824.png"
              alt="google_logo"
              style={{height: "20px", width: "20px"}}></img>
            Sign in with Google
          </button>
        </div>
        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <button
            className="btn btn-secondary"
            onClick={(event) => openPerformanceMetric("Google", event)}>
            <i className="bi bi-gear"></i>
          </button>
        </div>
        <div className="col-md-6">
          <p>
            Please ensure that you have an ad account associated with the Google
            profile that you're logging in with.
          </p>
        </div>
      </div>
      {/* TradeDesk */}
      <div className="row text-white py-2 d-flex align-items-center border-bottom">
        <div className="col-md-4 d-flex align-items-center justify-content-center">
          <button
            className="btn btn-dark api-button d-flex align-items-center"
            onClick={(event) => openModal("TradeDesk", event)}
            style={{width: "225px"}}
            // disabled={true}
          >
            <img
              src={whiteLabel.TradeDesk_logo}
              alt="tradedesk_logo"
              className="me-2"
              style={{height: "20px", width: "20px"}}
            />
            Sign in with Trade Desk
          </button>
        </div>
        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <button
            className="btn btn-secondary"
            onClick={(event) => openPerformanceMetric("TradeDesk", event)}
            // disabled={true}
          >
            <i className="bi bi-gear"></i>
          </button>
        </div>
        <div className="col-md-6">
          <p>
            Please ensure that you have an ad account associated with the Trade
            Desk profile that you're logging in with.
          </p>
        </div>
      </div>
      {/* Reddit */}
      <div className="row text-white py-2 d-flex align-items-center border-bottom">
        <div className="col-md-4 d-flex align-items-center justify-content-center">
          <button
            className="btn btn-danger api-button d-flex align-items-center"
            onClick={() => ConnectAPI("Reddit")}
            style={{width: "225px"}}>
            <i className="bi bi-reddit me-2 fs-5"></i>
            Sign in with Reddit
          </button>
        </div>
        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <button
            className="btn btn-secondary"
            onClick={(event) => openPerformanceMetric("Reddit", event)}>
            <i className="bi bi-gear"></i>
          </button>
        </div>
        <div className="col-md-6">
          <p>
            Please ensure that you have an ad account associated with the Reddit
            profile that you're logging in with.
          </p>
        </div>
      </div>
      {/* LinkedIn */}
      <div className="row text-white py-2 d-flex align-items-center border-bottom">
        <div className="col-md-4 d-flex align-items-center justify-content-center">
          <button
            className="btn btn-primary api-button d-flex align-items-center"
            onClick={() => ConnectAPI("Linkedin")}
            style={{width: "225px"}}>
            <i className="bi bi-linkedin text-light me-2 fs-5"></i>
            Sign in with LinkedIn
          </button>
        </div>
        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <button
            className="btn btn-secondary"
            onClick={(event) => openPerformanceMetric("Linkedin", event)}>
            <i className="bi bi-gear"></i>
          </button>
        </div>
        <div className="col-md-6">
          <p>
            Please ensure that you have an ad account associated with the
            LinkedIn profile that you're logging in with.
          </p>
        </div>
      </div>
      {/* Bing */}
      <div className="row text-white py-2 d-flex align-items-center border-bottom">
        <div className="col-md-4 d-flex align-items-center justify-content-center">
          <button
            className="btn btn-light api-button d-flex align-items-center"
            onClick={() => ConnectAPI("Bing")}
            style={{width: "225px"}}
            disabled={true}>
            <i className="bi bi-bing text-info me-2 fs-5"></i>
            Sign in with Bing
          </button>
        </div>
        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <button
            className="btn btn-secondary"
            onClick={(event) => openPerformanceMetric("Bing", event)}
            disabled={true}>
            <i className="bi bi-gear"></i>
          </button>
        </div>
        <div className="col-md-6">
          <p>
            Please ensure that you have an ad account associated with the Bing
            profile that you're logging in with.
          </p>
        </div>
      </div>
      {performanceMatrixOpen && (
        <PerformanceMetric
          show={performanceMatrixOpen}
          platform={SelectedPlatform}
          onHide={closeModal}
          setMessage={setMessage}
          setShowSuccessMessage={setShowSuccessMessage}
          setShowErrorMessage={setShowErrorMessage}
        />
      )}
      {SelectedPlatform === "TradeDesk" ? (
        <Modal
          show={modalOpen}
          onHide={closeModal}
          dialogClassName="modal-scroll">
          <Modal.Header closeButton>
            <Modal.Title>Token Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center mb-3">
              <img
                src={whiteLabel.TradeDesk_img}
                alt="banner"
                style={{height: "26px", width: "210px"}}
              />
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <div className="mb-2">
                    <label
                      htmlFor="token"
                      className="form-label text-black mb-0">
                      Enter Token:
                    </label>
                    <div>
                      <div className="sub-text" style={{fontSize: "12px"}}>
                        <a
                          href="https://partner.thetradedesk.com/v3/portal/api/doc/Authentication#ui-method-create"
                          target="_blank"
                          rel="noreferrer"
                          title="Click here to view steps"
                          className="mx-1"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}>
                          Steps to generate Token
                        </a>
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="token"
                    name="token"
                    placeholder="Enter token"
                    value={formData.token}
                    onChange={handleChange}
                    required={true}
                  />
                </div>
                <div className="d-flex mb-3">
                  <div className="startdate mx-2">
                    Start Date:
                    <i className="bi bi-info-circle ms-1" title="Start Date for the Token" style={{cursor: "pointer"}}></i>
                    <DatePicker
                      className="form-control border-secondary p-1 mx-1"
                      selected={
                        formData.start_date
                          ? new Date(formData.start_date)
                          : null
                      }
                      onChange={(date) => handleDateChange(date, "start_date")}
                      placeholderText="Start Date"
                      dateFormat="MMM dd, yyyy"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      maxDate={
                        formData.asset_end_date
                          ? new Date(formData.end_date)
                          : null
                      }
                      required={true}
                    />
                  </div>
                  <div className="enddate mx-2">
                    End Date:
                    <i className="bi bi-info-circle ms-1" title="Expiry Date for the Token" style={{cursor: "pointer"}}></i>
                    <DatePicker
                      className="form-control p-1 mx-1"
                      selected={
                        formData.end_date ? new Date(formData.end_date) : null
                      }
                      onChange={(date) => handleDateChange(date, "end_date")}
                      placeholderText="End Date"
                      dateFormat="MMM dd, yyyy"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      minDate={
                        formData.start_date
                          ? new Date(formData.start_date)
                          : null
                      }
                      required={true}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="performance_report_name"
                    className="form-label text-black">
                    Performance Report Name:
                    <i
                      className="bi bi-info-circle ms-2"
                      title="Performance report name which will be generated by TradeDesk Dashboard
                      and used to show performance data"
                      style={{cursor: "pointer"}}></i>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="performance_report_name"
                    name="performance_report_name"
                    placeholder="Please add performance report name here"
                    value={formData.performance_report_name}
                    onChange={handleChange}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="description"
                    className="form-label text-black">
                    Description / Notes:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    name="description"
                    placeholder="Please add description here"
                    value={formData.description}
                    onChange={handleChange}
                    required={true}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    onClick={closeModal}>
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          show={modalOpen}
          onHide={closeModal}
          dialogClassName="modal-scroll">
          <Modal.Header closeButton>
            <Modal.Title>Before you connect...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {SelectedPlatform === "Meta" ? (
              <div className="container">
                <span>
                  If you have multiple pages, Business accounts, and/or ad
                  accounts, please ensure only one of each is selected.
                </span>
              </div>
            ) : (
              <div className="border border-black rounded p-2">
                <h5 className="d-flex justify-content-center">Consent Form</h5>
                <p
                  className="container"
                  style={{
                    maxHeight: "250px",
                    overflowY: "scroll",
                    textAlign: "justify",
                  }}>
                  Authorization for Accessing Social Media Data I,{" "}
                  <b>{props.user.name}</b>, hereby authorize <b>mktg.ai</b>,
                  hereinafter referred to as "the Company," to access and
                  collect data from my social media accounts for the purpose of
                  managing, analyzing and measuring performance of digital and
                  non-digital assets. Scope of Access: The Company is granted
                  permission to access my <b>Google</b> posts, advertisements,
                  and insights. This access includes but is not limited to media
                  posts, assets, statastics, etc. Data Usage: The data collected
                  will be used solely for the purpose marketing analysis. The
                  Company assures that the data collected will not be shared
                  with any third-party tools or services without my explicit
                  consent. I understand that my data may be used in an
                  aggregated and anonymized form for statistical analysis and
                  research purposes. Data Security: The Company is committed to
                  storing all collected data securely and confidentially.
                  Measures will be taken to prevent unauthorized access,
                  disclosure, alteration, or destruction of the data. Access to
                  my data will be restricted to authorized personnel only. By
                  clicking the <b>"I Agree"</b> button, I also consent to the
                  use of AI platforms that include but are not limited to Google
                  Vertex AI, and Openai GPT-4 to optimize your experience,
                  including image and video classification for refined search,
                  semantic and similarity queries, and content search. Upholding
                  strict privacy standards, we share your information solely to
                  enhance our services, ensuring transparency and
                  accountability.
                </p>
                <div className="form-check">
                  <input
                    className="form-check-input border border-dark"
                    type="checkbox"
                    checked={isChecked}
                    id="consentCheckbox"
                    onChange={() => setIsChecked(!isChecked)}
                  />
                  <label className="form-check-label" htmlFor="consentCheckbox">
                    <b>
                      I Agree to the terms and conditions outlined in this
                      consent form.
                    </b>
                  </label>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}>
              Cancel
            </button>
            {SelectedPlatform === "Meta" ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => ConnectAPI(SelectedPlatform)}>
                Yes, I understand
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => ConnectAPI(SelectedPlatform)}
                disabled={!isChecked}>
                Yes, I understand
              </button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default Dashboard_API;
