import React, {useState, useEffect} from "react";
import axios from "axios";
import {getAPIConfig} from "../common/utils";
import {encryptPassword} from "../common/helpers/utils/PasswordHelper";
import PasswordValidation from "../common/components/PasswordValidation";
import ENDPOINTS from "../common/endpoints";
import {useLocation, useNavigate} from "react-router-dom";
import Spinner from "../components/spinner";

const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const encryptedPassword = encryptPassword(newPassword);
    const requestBody = {
      token,
      password: encryptedPassword,
    };

    axios
      .post(ENDPOINTS.AUTH.reset_password, requestBody, getAPIConfig())
      .then(() => {
        setIsLoading(false);
        setSuccessMessage("Password reset successfully!");
        setTimeout(() => navigate("/client-login"), 2000);
      })
      .catch(() => {
        setIsLoading(false);
        setError("Failed to reset password. Please try again.");
      });
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${ENDPOINTS.AUTH.reset_password}?token=${token}`, getAPIConfig())
      .then((response) => {
        setIsLoading(false);
        setEmail(response.data?.email);
      })
      .catch(() => {
        setIsLoading(false);
        setError("Token Expired. Please try again.");
        setTimeout(() => navigate("/client-login"), 3000);
      });
  }, [token, navigate]);

  return (
    <div className="container-fluid mt-2">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <h2 className="d-flex justify-content-center">Reset Password</h2>
          <form onSubmit={handleSubmit}>
            <div className="my-3">
              <div className="mb-3">
                <div className="mb-2 text-white">
                  Email Address<span className="text-danger">*</span>
                </div>
                <input
                  name="email"
                  type="email"
                  value={email}
                  className="form-control inputs border-0 text-dark"
                  disabled
                />
              </div>
              <PasswordValidation
                password={newPassword}
                confirmPassword={confirmPassword}
                setPassword={setNewPassword}
                setConfirmPassword={setConfirmPassword}
                setIsPasswordValid={setIsPasswordValid}
                isPasswordValid={isPasswordValid}
                customClasses={{
                  input: "inputs border-0 text-white", // Custom input class
                  icon: {
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "white",
                  },
                }}
                checklistStyles={{
                  marginTop: "10px",
                  backgroundColor: "#343a40",
                  padding: "5px",
                  borderRadius: "5px",
                  color: "white",
                }}
              />
            </div>
            {successMessage && (
              <div className="alert alert-success">{successMessage}</div>
            )}
            {error && <div className="alert alert-danger">{error}</div>}
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                isLoading ||
                !newPassword ||
                !confirmPassword ||
                newPassword !== confirmPassword ||
                !isPasswordValid
              }>
              Reset Password
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default ResetPasswordPage;
