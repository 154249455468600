import * as d3 from "d3";

const updatePercentileValue = (
  percentiles,
  lineKey,
  isreverse,
  newPercentileValue
) => {
  const updatedPercentiles = {...percentiles};
  const boundaryAdjustment = 0.0001;

  if (lineKey === "low_score") {
    updatedPercentiles.low_score = isreverse
      ? Math.max(
          percentiles.average_score + boundaryAdjustment,
          newPercentileValue
        )
      : Math.min(
          newPercentileValue,
          percentiles.average_score - boundaryAdjustment
        );
  } else if (lineKey === "average_score") {
    updatedPercentiles.average_score = isreverse
      ? Math.max(
          percentiles.good_score + boundaryAdjustment,
          Math.min(
            newPercentileValue,
            percentiles.low_score - boundaryAdjustment
          )
        )
      : Math.max(
          percentiles.low_score + boundaryAdjustment,
          Math.min(
            newPercentileValue,
            percentiles.good_score - boundaryAdjustment
          )
        );
  } else if (lineKey === "good_score") {
    updatedPercentiles.good_score = isreverse
      ? Math.max(
          percentiles.excellent_score + boundaryAdjustment,
          Math.min(
            newPercentileValue,
            percentiles.average_score - boundaryAdjustment
          )
        )
      : Math.max(
          percentiles.average_score + boundaryAdjustment,
          Math.min(
            newPercentileValue,
            percentiles.excellent_score - boundaryAdjustment
          )
        );
  } else if (lineKey === "excellent_score") {
    updatedPercentiles.excellent_score = isreverse
      ? Math.min(
          newPercentileValue,
          percentiles.good_score - boundaryAdjustment
        )
      : Math.max(
          percentiles.good_score + boundaryAdjustment,
          newPercentileValue
        );
  }

  return updatedPercentiles;
};

export const handleDrag = (
  event,
  lineKey,
  xScale,
  width,
  height,
  margin,
  leftMargin,
  rightMargin,
  percentiles,
  setPercentiles,
  isreverse,
  setFormData,
  formData,
  platform,
  activeSubPlatform,
  activeCategory,
  activeType,
  metric_name,
  updateScores
) => {
  const newX = Math.min(width - rightMargin, Math.max(leftMargin, event.x));
  const newPercentileValue = xScale.invert(newX);
  const updatedPercentiles = updatePercentileValue(
    percentiles,
    lineKey,
    isreverse,
    newPercentileValue
  );

  setPercentiles(updatedPercentiles);

  // Update formData with the new percentile values
  const updatedFormData = {...formData};
  const targetData =
    updatedFormData[platform]?.[activeSubPlatform]?.[activeCategory]?.[
      activeType
    ];

  if (targetData) {
    const percentilesData = {
      pointers: {
        excellent_score: parseFloat(
          updatedPercentiles.excellent_score.toFixed(4)
        ),
        good_score: parseFloat(updatedPercentiles.good_score.toFixed(4)),
        average_score: parseFloat(updatedPercentiles.average_score.toFixed(4)),
        low_score: parseFloat(updatedPercentiles.low_score.toFixed(4)),
      },
    };

    // Use the updateScores function to handle updating dynamically
    updateScores(percentilesData, updatedFormData, targetData, metric_name);
  }

  // Update the formData state
  setFormData(updatedFormData);

  const cx = xScale(updatedPercentiles[lineKey]);

  // Update line position
  d3.select(`.${lineKey}-line.${metric_name}`)
    .transition()
    .duration(50)
    .attr("x1", cx)
    .attr("x2", cx);

  // Update pentagon handle (rectangle + triangle)
  const handle = d3.select(`.${lineKey}-handle.${metric_name}`);
  const cy = height - margin.bottom + 38; // Ensure alignment with the bottom of the line
  const triangleHeight = 10;
  const triangleBase = 12;
  const rectHeight = 15;

  handle
    .select("rect")
    .transition()
    .duration(50)
    .attr("x", cx - triangleBase / 2)
    .attr("y", cy + triangleHeight)
    .attr("width", triangleBase)
    .attr("height", rectHeight);

  handle
    .select("polygon")
    .transition()
    .duration(50)
    .attr(
      "points",
      [
        [cx, cy], // Tip of the triangle
        [cx - triangleBase / 2, cy + triangleHeight],
        [cx + triangleBase / 2, cy + triangleHeight],
      ]
        .map((p) => p.join(","))
        .join(" ")
    );

  // Update the value text position
  d3.select(`.${lineKey}-value.${metric_name}`)
    .transition()
    .duration(50)
    .attr("x", cx)
    .text(updatedPercentiles[lineKey].toFixed(4));
};

export const generateBellCurve = (data) => {
  const xValues = data.x;
  const yValues = data.y;
  return xValues.map((x, i) => ({x, y: yValues[i]}));
};

export const calculateScoreRanges = (percentiles, isreverse, x) => {
  return isreverse
    ? [
        {min: percentiles.low_score, max: x.domain()[1], color: "#cf0000"},
        {
          min: percentiles.average_score,
          max: percentiles.low_score,
          color: "#ff6344",
        },
        {
          min: percentiles.good_score,
          max: percentiles.average_score,
          color: "#FFFF00",
        },
        {
          min: percentiles.excellent_score,
          max: percentiles.good_score,
          color: "#b2ff6b",
        },
        {
          min: d3.min([0, x.domain()[0]]),
          max: percentiles.excellent_score,
          color: "#00a318",
        },
      ]
    : [
        {
          min: d3.min([0, x.domain()[0]]),
          max: percentiles.low_score,
          color: "#cf0000",
        },
        {
          min: percentiles.low_score,
          max: percentiles.average_score,
          color: "#ff6344",
        },
        {
          min: percentiles.average_score,
          max: percentiles.good_score,
          color: "#FFFF00",
        },
        {
          min: percentiles.good_score,
          max: percentiles.excellent_score,
          color: "#b2ff6b",
        },
        {
          min: percentiles.excellent_score,
          max: x.domain()[1],
          color: "#00a318",
        },
      ];
};
