import * as d3 from "d3";

export const createPercentileSliders = (
  svg,
  percentileLines,
  percentiles,
  x,
  width,
  height,
  margin,
  isreverse,
  handleDrag,
  getCounts,
  setPercentiles,
  formData,
  setFormData,
  platform,
  activeSubPlatform,
  activeCategory,
  activeType,
  metric_name,
  updateScores
) => {
  const lineHeights = [
    "low_score",
    "average_score",
    "good_score",
    "excellent_score",
  ].reduce((acc, key, index) => {
    acc[key] = margin.top;
    return acc;
  }, {});

  const lineBottom = height - margin.bottom + 40;

  percentileLines.forEach((line) => {
    // Draw the line and handle mouse events for text visibility
    svg
      .append("line")
      .attr("x1", x(percentiles[line.key] || 0))
      .attr("x2", x(percentiles[line.key] || 0))
      .attr("y1", lineHeights[line.key])
      .attr("y2", lineBottom)
      .attr("stroke", "white")
      .attr("stroke-width", 3)
      .attr("opacity", 0.8)
      .attr("class", `${line.key}-line draggable-line ${metric_name}`)
      .style("cursor", "ew-resize")
      .call(
        d3
          .drag()
          .on("drag", (event) =>
            handleDrag(
              event,
              line.key,
              x,
              width,
              height,
              margin,
              margin.left,
              margin.right,
              percentiles,
              setPercentiles,
              isreverse,
              setFormData,
              formData,
              platform,
              activeSubPlatform,
              activeCategory,
              activeType,
              metric_name,
              updateScores
            )
          )
          .on("end", () => {
            getCounts(metric_name, line.key);
          })
      );

    // Add handle (inverted triangle + rectangle)
    svg
      .append("g")
      .attr("class", `${line.key}-handle ${metric_name}`)
      .style("cursor", "pointer")
      .call(
        d3
          .drag()
          .on("drag", (event) =>
            handleDrag(
              event,
              line.key,
              x,
              width,
              height,
              margin,
              margin.left,
              margin.right,
              percentiles,
              setPercentiles,
              isreverse,
              setFormData,
              formData,
              platform,
              activeSubPlatform,
              activeCategory,
              activeType,
              metric_name,
              updateScores
            )
          )
          .on("end", () => {
            getCounts(metric_name, line.key);
          })
      )
      .each(function () {
        const cx = x(percentiles[line.key] || 0);
        const cy = lineBottom - 2;
        const triangleHeight = 10;
        const triangleBase = 12;
        const rectHeight = 15;
        const rectWidth = triangleBase;

        // Inverted triangle
        d3.select(this)
          .append("polygon")
          .attr(
            "points",
            [
              [cx, cy],
              [cx - triangleBase / 2, cy + triangleHeight],
              [cx + triangleBase / 2, cy + triangleHeight],
            ]
              .map((p) => p.join(","))
              .join(" ")
          )
          .attr("fill", "white");

        // Rectangle
        d3.select(this)
          .append("rect")
          .attr("x", cx - rectWidth / 2)
          .attr("y", cy + triangleHeight)
          .attr("width", rectWidth)
          .attr("height", rectHeight)
          .attr("fill", "white");
      });

    // Add value text and hide initially
    const valueText = svg
      .append("text")
      .attr("x", x(percentiles[line.key] || 0))
      .attr("y", lineBottom + 35)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("font-weight", "bold")
      .attr("fill", "white")
      .text(`${(percentiles[line.key] || 0).toFixed(4)}`)
      .attr("class", `${line.key}-value ${metric_name}`)
      .style("opacity", 0); // Initially hidden

    // Attach native event listeners
    const lineElement = svg.select(`.${line.key}-line`).node();
    const handleElement = svg.select(`.${line.key}-handle`).node();

    // Add transition to the valueText for smooth opacity change
    valueText.style("transition", "opacity 0.3s ease");

    lineElement.addEventListener("mouseover", () => {
      valueText.style("opacity", 1);
    });

    lineElement.addEventListener("mouseout", () => {
      valueText.style("opacity", 0);
    });

    handleElement.addEventListener("mouseover", () => {
      valueText.style("opacity", 1);
    });

    handleElement.addEventListener("mouseout", () => {
      valueText.style("opacity", 0);
    });

    handleElement.addEventListener("dragstart", () => {
      valueText.style("opacity", 1);
    });

    handleElement.addEventListener("dragend", () => {
      valueText.style("opacity", 0);
    });
  });
  
};
