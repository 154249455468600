import React, {useState, useEffect, useMemo, useRef} from "react";
import Spinner from "../../../../components/spinner";
import axios from "axios";
import {getAPIConfig} from "../../../../common/utils";
import { formatDateForBackend } from "../../utils/utils";
import {labelFormatter} from "../../../../common/helpers/utils/Formatter";
import {
  capitalize,
  isObjWithKeys,
  getValue,
  setValue,
  deepCopy,
} from "../../../../common/helpers/utils/DataHelper";
import {getScorePair} from "../utils/utils";
import ENDPOINTS from "../../../../common/endpoints";
import whiteLabel from "../../../../assets/whitelabelConfig";
import TabSection from "./TabSection";
// import UpdateDetails from "./UpdateDetails";
import MetricSection from "./MetricSection";

const PerformanceForm = ({
  platforms,
  handlePlatformChange,
  platform,
  metricData,
  formData,
  setFormData,
  typeData,
  activeSubPlatform,
  setActiveSubPlatform,
  activeCategory,
  setActiveCategory,
  activeType,
  setActiveType,
  performanceFieldValues,
  handleChange,
  handleBlur,
  handleSubmit,
  isEditMode,
  setIsEditMode,
  showSubmitButton,
  isLoading,
  hasError,
  handleCancel,
  lastUpdatedUserDetail,
  showcheckbox,
}) => {
  const apiHeaderConfig = useMemo(() => getAPIConfig(), []);
  const [graphStates, setGraphStates] = useState({});
  const [metrics, setMetrics] = useState([]);
  const [nextId, setNextId] = useState(1);
  const [hasGeneratedGraph, setHasGeneratedGraph] = useState(false);
  const initialGraphStatesRef = useRef({});
  const initialMetricsStatesRef = useRef({});
  const abortControllers = useRef({});
  const use_platform_metric =
    Object.values(performanceFieldValues)[0]?.use_platform_metric || false;

  const user = JSON.parse(localStorage.getItem("user"));
  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1); // 12 months ago
  const endDate = new Date(); // Today's date

  const resetScores = (updatedFormData, targetData, metric_name) => {
    if (targetData && metric_name && targetData[metric_name]) {
      targetData[metric_name] = {
        excellent_score: "",
        good_score: "",
        average_score: "",
        low_score: "",
        use_platform_metric: false,
        is_remove_config: false,
        is_increasing_score: true,
      };
    }
    setFormData(updatedFormData);
    setGraphStates((prev) => ({
      ...prev,
      [metric_name]: {graphData: {}, isGraphVisible: false, isLoading: false},
    }));
  };

  const updateScores = (data, updatedFormData, targetData, metric_name) => {
    if (targetData && metric_name) {
      if (!targetData[metric_name]) {
        targetData[metric_name] = {
          excellent_score: "",
          good_score: "",
          average_score: "",
          low_score: "",
          use_platform_metric: false,
          is_remove_config: false,
          is_increasing_score: true,
        };
      }

      targetData[metric_name] = {
        ...targetData[metric_name],
        ...data.pointers,
      };
    }
    setFormData(updatedFormData);
  };

  const generateGraph = (metric_name, isreverse, metric_startDate, metric_endDate) => {
    const body = {
      client_id: user.client_id,
      media_platform: platform,
      sub_media_platform: activeSubPlatform,
      category: activeCategory,
      type: activeType,
      metric_field: metric_name,
      is_reverse: isreverse,
      start_date: formatDateForBackend(metric_startDate),
      end_date: formatDateForBackend(metric_endDate),
    };

    setGraphStates((prev) => ({
      ...prev,
      [metric_name]: {...prev[metric_name], isLoading: true},
    }));

    axios
      .post(ENDPOINTS.PERFORMANCE_METRIC.generateGraph, body, apiHeaderConfig)
      .then((response) => {
        const data = response.data.data;

        if (data && isObjWithKeys(data)) {
          const updatedFormData = {...formData};
          const targetData =
            updatedFormData[platform]?.[activeSubPlatform]?.[activeCategory]?.[
              activeType
            ];
          updateScores(data, updatedFormData, targetData, metric_name);

          setGraphStates((prev) => ({
            ...prev,
            [metric_name]: {
              graphData: data,
              isGraphVisible: data.is_graph_exist,
              isLoading: false,
              showSpinner: false,
              pointersLoading: {},
            },
          }));
        }
      })
      .catch(() => {
        const updatedFormData = {...formData};
        const targetData =
          updatedFormData[platform]?.[activeSubPlatform]?.[activeCategory]?.[
            activeType
          ];
        resetScores(updatedFormData, targetData, metric_name);
      })
      .finally(() => {
        setGraphStates((prev) => ({
          ...prev,
          [metric_name]: {...prev[metric_name], isLoading: false},
        }));
      });
  };

  const generateMultipleGraph = (metrics) => {
    if (
      metrics.some(
        (metric) =>
          metric.name === "null" ||
          metric.name === "" ||
          metric.name === "default"
      )
    ) {
      return; // Return early without making an API call
    }
    const requestBody = metrics.map((metric) => ({
      client_id: user.client_id,
      media_platform: platform,
      sub_media_platform: activeSubPlatform,
      category: activeCategory,
      type: activeType,
      metric_field: metric.name,
      is_reverse: !metric.is_increasing_score,
    }));

    // Set all graphs to loading initially
    setGraphStates((prev) => {
      const newState = {...prev};
      metrics.forEach((metric) => {
        newState[metric.name] = {...newState[metric.name], isLoading: true};
      });
      return newState;
    });

    axios
      .post(
        ENDPOINTS.PERFORMANCE_METRIC.generateMultipleGraph,
        requestBody,
        apiHeaderConfig
      )
      .then((response) => {
        const data = response.data.data; //array of objects, each containing metric data

        if (Array.isArray(data) && data.length > 0) {
          const updatedFormData = {...formData};
          const targetData =
            updatedFormData[platform]?.[activeSubPlatform]?.[activeCategory]?.[
              activeType
            ];

          // Loop through the response data array
          data.forEach((metricDataObj) => {
            const metric_name = metricDataObj?.metric_field; // Extract the metric name from the response object

            if (metric_name) {
              // Find the corresponding metric from the metrics array
              const metric = metrics.find((m) => m.name === metric_name);

              if (metric) {
                updateScores(
                  metricDataObj,
                  updatedFormData,
                  targetData,
                  metric_name
                );

                setGraphStates((prev) => {
                  const updatedState = {
                    ...prev,
                    [metric_name]: {
                      graphData: metricDataObj,
                      isGraphVisible: metricDataObj.is_graph_exist,
                      isLoading: false,
                      showSpinner: false,
                      pointersLoading: {},
                    },
                  };

                  // Update the initialGraphStatesRef with the current state
                  initialGraphStatesRef.current = updatedState;

                  return updatedState;
                });
              }
            }
          });
        }
      })
      .catch(() => {
        const updatedFormData = {...formData};
        const targetData =
          updatedFormData[platform]?.[activeSubPlatform]?.[activeCategory]?.[
            activeType
          ];

        metrics.forEach((metric) => {
          resetScores(updatedFormData, targetData, metric.name);
        });
      })
      .finally(() => {
        setGraphStates((prev) => {
          const newState = {...prev};
          metrics.forEach((metric) => {
            newState[metric.name] = {
              ...newState[metric.name],
              isLoading: false,
            };
          });
          return newState;
        });
      });
  };

  const getCounts = (metric_name, pointer) => {
    // Check if the metric name is valid
    if (
      metrics.some(
        (metric) =>
          metric.name === "null" ||
          metric.name === "" ||
          metric.name === "default"
      )
    ) {
      return; // Return early without making an API call
    }

    // Check for existing ongoing request with the same metric_name
    if (abortControllers.current[metric_name]) {
      // Cancel the ongoing request if it exists
      abortControllers.current[metric_name].abort();
    }

    // Create a new AbortController for the new request
    const controller = new AbortController();
    const {signal} = controller;

    // Store the controller in an object to manage ongoing requests
    abortControllers.current[metric_name] = controller;

    const metricData =
      formData?.[platform]?.[activeSubPlatform]?.[activeCategory]?.[
        activeType
      ]?.[metric_name] || {};

    const pointers = [
      "average_score",
      "excellent_score",
      "good_score",
      "low_score",
    ].reduce((acc, key) => {
      acc[key] = parseFloat(metricData[key]) || 0;
      return acc;
    }, {});

    const requestBody = {
      client_id: user.client_id,
      media_platform: platform,
      sub_media_platform: activeSubPlatform,
      category: activeCategory,
      type: activeType,
      metric_field: metric_name,
      pointers: pointers,
      is_reverse: !metricData.is_increasing_score,
    };

    // Set Spinner for counts
    setGraphStates((prev) => {
      const newState = {...prev};
      const [firstScore, secondScore] = getScorePair(pointer);
      newState[metric_name] = {
        ...newState[metric_name],
        showSpinner: true,
        pointersLoading: {firstScore, secondScore},
      };
      return newState;
    });

    // Make the API call with the signal to handle request cancellation
    axios
      .post(
        ENDPOINTS.PERFORMANCE_METRIC.getAssetCounts,
        requestBody,
        {...apiHeaderConfig, signal} // Pass the signal to the axios request
      )
      .then((response) => {
        const data = response.data.data; // Array of objects, each containing metric data

        // Update the graph states with the new asset counts
        setGraphStates((prev) => {
          const updatedState = {...prev};

          updatedState[metric_name] = {
            ...updatedState[metric_name],
            graphData: {
              ...updatedState[metric_name]?.graphData,
              asset_counts: data, // Add the asset counts data here
            },
            isGraphVisible:
              updatedState[metric_name]?.graphData?.is_graph_exist,
            isLoading: false,
            showSpinner: false,
          };

          return updatedState;
        });
      })
      .catch((error) => {
        // If the request was aborted, do nothing
        if (axios.isCancel(error)) {
          return; // Request was canceled, no need to update state
        }

        // Handle error case (e.g., network error)
        setGraphStates((prev) => {
          const updatedState = {...prev};

          updatedState[metric_name] = {
            ...updatedState[metric_name],
            graphData: {
              ...updatedState[metric_name]?.graphData,
              asset_counts: "Data Unavailable", // Set default message
            },
            isGraphVisible:
              updatedState[metric_name]?.graphData?.is_graph_exist,
            isLoading: false,
            showSpinner: false,
            pointersLoading: {},
          };

          // Update the initialGraphStatesRef with the updated state
          initialGraphStatesRef.current = updatedState;

          return updatedState;
        });
      });
  };

  const handleAddMore = (e) => {
    e.preventDefault();
    if (metrics.length < 3) {
      setMetrics([
        ...metrics,
        {
          id: nextId,
          name: "",
          excellent_score: "",
          good_score: "",
          average_score: "",
          low_score: "",
          updated_by_user: "",
          use_platform_metric: false,
          is_remove_config: false,
          is_increasing_score: true,
          startDate: startDate,
          endDate: endDate,
        },
      ]);
      setNextId(nextId + 1);
    }
  };

  const handleRemove = (id, metric_name) => {
    setMetrics(metrics.filter((metric) => metric.id !== id));
    setFormData((prevState) => {
      const updatedState = {...prevState};
      const performanceMetricFieldPath = [
        platform,
        activeSubPlatform,
        activeCategory,
      ].join(".");
      const metricPath = `${performanceMetricFieldPath}.${activeType}`; // Construct the path to metrics
      const metrics = getValue(updatedState, metricPath) || {};

      // Find the metric by id and update its is_remove_config property
      const updatedMetrics = Object.entries(metrics).reduce(
        (acc, [key, value]) => {
          if (key === metric_name) {
            acc[key] = {...value, is_remove_config: true}; // Set is_remove_config to true
          } else {
            acc[key] = value; // Keep other metrics unchanged
          }
          return acc;
        },
        {}
      );

      // Update the state with the modified metrics
      setValue(updatedState, metricPath, updatedMetrics);
      return updatedState;
    });
    setGraphStates((prevGraphStates) => {
      const {[metric_name]: removedMetric, ...updatedGraphStates} =
        prevGraphStates;
      return updatedGraphStates; // Return the updated graphStates without the removed metric
    });
  };

  useEffect(() => {
    // Only set initialMetricsStatesRef.current on the first render
    if (Object.keys(initialMetricsStatesRef.current).length === 0) {
      const initialMetrics = Object.entries(performanceFieldValues || {}).map(
        ([key, value], idx) => ({
          id: value.display_order || 1,
          name: key,
          is_increasing_score: value.is_increasing_score ?? true,
          is_remove_config: value.is_remove_config ?? false,
          excellent_score: value.excellent_score ?? "",
          good_score: value.good_score ?? "",
          low_score: value.low_score ?? "",
          average_score: value.average_score ?? "",
          updated_by_user: value.updated_by_user ?? "",
          use_platform_metric: value.use_platform_metric ?? false,
          startDate: startDate,
          endDate: endDate,
        })
      );

      // Set the reference only on the first load
      initialMetricsStatesRef.current = initialMetrics;

      const maxId = initialMetrics.reduce(
        (max, metric) => Math.max(max, metric.id),
        0
      );
      setNextId(maxId + 1);
      setMetrics(initialMetrics); // Set the metrics state
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (metrics.length > 0 && !hasGeneratedGraph) {
      generateMultipleGraph(metrics);
      setHasGeneratedGraph(true); // Set the flag to true so it doesn't trigger again
    }
    // eslint-disable-next-line
  }, [metrics, hasGeneratedGraph]); // This will run only when `metrics` is set for the first time

  return (
    <form onSubmit={handleSubmit}>
      <TabSection
        platforms={platforms}
        handlePlatformChange={handlePlatformChange}
        platform={platform}
        whiteLabel={whiteLabel}
        metricData={metricData}
        activeSubPlatform={activeSubPlatform}
        setActiveSubPlatform={setActiveSubPlatform}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        activeType={activeType}
        setActiveType={setActiveType}
        use_platform_metric={use_platform_metric}
        handleChange={handleChange}
        labelFormatter={labelFormatter}
        capitalize={capitalize}
        isEditMode={isEditMode}
        showSubmitButton={showSubmitButton}
        showcheckbox={showcheckbox}>
        <div className="row mt-1">
          <div className="col d-flex align-items-center">
            <div className="d-flex">
              {showSubmitButton ? (
                <button
                  className="btn btn-primary btn-sm"
                  type="submit"
                  disabled={isLoading || hasError}>
                  Submit
                </button>
              ) : (
                <div className="d-flex">
                  {!isEditMode ? (
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEditMode(true);
                      }}>
                      Edit
                    </button>
                  ) : (
                    <>
                      <button
                        className="btn btn-secondary btn-sm me-2"
                        disabled={isLoading}
                        onClick={(e) => {
                          // Reset graph states
                          setGraphStates(
                            deepCopy(initialGraphStatesRef.current)
                          );
                          setMetrics(deepCopy(initialMetricsStatesRef.current));
                          handleCancel(e);
                        }}>
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading || hasError}>
                        Update
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {metrics
          .slice()
          .sort((a, b) => a.id - b.id)
          .map((metric, index) => (
            <>
              <MetricSection
                key={metric.id}
                metrics={metrics}
                metric={metric}
                setMetrics={setMetrics}
                index={index}
                isMainSection={index === 0}
                handleRemove={handleRemove}
                handleChange={handleChange}
                handleBlur={handleBlur}
                generateGraph={generateGraph}
                graphData={graphStates[metric.name]?.graphData || {}}
                setGraphStates={setGraphStates}
                isgraphvisible={
                  graphStates[metric.name]?.isGraphVisible || false
                }
                isGraphLoading={graphStates[metric.name]?.isLoading || false}
                showSpinner={graphStates[metric.name]?.showSpinner}
                pointersLoading={graphStates[metric.name]?.pointersLoading}
                formData={formData}
                setFormData={setFormData}
                platform={platform}
                activeSubPlatform={activeSubPlatform}
                activeCategory={activeCategory}
                activeType={activeType}
                use_platform_metric={use_platform_metric}
                isEditMode={isEditMode}
                showSubmitButton={showSubmitButton}
                typeData={typeData}
                Spinner={Spinner}
                updateScores={updateScores}
                getCounts={getCounts}
              />
              <hr className="mt-4" />
            </>
          ))}
        <div className="row mt-1">
          <div className="d-flex">
            {/* Add More Button */}
            {isEditMode &&
              metrics.length < 3 &&
              !Object.values(performanceFieldValues)[0]
                ?.use_platform_metric && (
                <button
                  className="btn btn-light btn-sm d-flex"
                  onClick={handleAddMore}>
                  <i className="bi bi-plus-circle-fill me-1"></i> Add More
                </button>
              )}
          </div>
        </div>
      </TabSection>
    </form>
  );
};

export default PerformanceForm;
