import React, {useState, useEffect, useMemo, useRef} from "react";
import Spinner from "../../../../components/spinner";
import axios from "axios";
import "../../../../Styles/style.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PerformanceForm from "./PerformanceForm";
import {
  capitalize,
  deepCopy,
  getValue,
  isObjWithKeys,
  setValue,
  stringToNumber,
} from "../../../../common/helpers/utils/DataHelper";
import {getAPIConfig} from "../../../../common/utils";
import ENDPOINTS from "../../../../common/endpoints";
import {
  formatPerformanceMetricData,
  getSubMediaPlatform,
  validateScores,
  validateGraphScores,
  getChanges,
} from "../utils/utils";
import localisable from "../../../../common/constants/localisable";
import {labelFormatter} from "../../../../common/helpers/utils/Formatter";
import {useParams, useNavigate} from "react-router-dom";

const PerformanceMetric = () => {
  const {platform} = useParams();
  const navigate = useNavigate();
  const initialMetricScores = {
    excellent_score: "",
    good_score: "",
    average_score: "",
    low_score: "",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [metricData, setMetricData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const initialFormDataRef = useRef(null);
  const initialPMFValuesRef = useRef(null);
  const [formData, setFormData] = useState({});
  const [activePlatform, setActivePlatform] = useState(platform);
  const platforms = [
    "Meta",
    "Google",
    "TradeDesk",
    "Reddit",
    "Linkedin",
    "Bing",
    "X",
  ];
  const [activeSubPlatform, setActiveSubPlatform] = useState("");
  const [activeCategory, setActiveCategory] = useState("");
  const [activeType, setActiveType] = useState("");
  const [typeData, setTypeData] = useState({});
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [lastUpdatedUserDetail, setLastUpdatedUserDetail] = useState({});
  const [errors, setErrors] = useState({});

  const apiHeaderConfig = useMemo(() => getAPIConfig(), []);
  const isMetricFieldAvailable = isObjWithKeys(metricData);
  const hasError = isObjWithKeys(errors);

  const performanceMetricFieldPath = [
    activePlatform,
    activeSubPlatform,
    activeCategory,
  ].join(".");
  const [performanceFieldValues, setPerformanceFieldValues] = useState(
    getValue(formData, `${performanceMetricFieldPath}.${activeType}`) || {}
  );

  const fetchMetricFields = (platformName) => {
    const body = {
      media_platform: platformName,
    };
    setIsLoading(true);
    axios
      .post(ENDPOINTS.PERFORMANCE_METRIC.getFields, body, apiHeaderConfig)
      .then((response) => {
        const data = response.data;
        const subPlatformData = data[platformName];
        const subPlatform =
          getValue(Object.keys(data[platformName] || {}), "0") || "";
        const categoryData = subPlatformData[subPlatform];
        const categoryKeys = Object.keys(categoryData);
        const activeCategory =
          categoryKeys.length > 1 ? categoryKeys[1] : categoryKeys[0] || "";
        const typeData = categoryData[activeCategory];
        setTypeData(typeData);
        const activeType = getValue(Object.keys(typeData), "0") || "";
        setActiveSubPlatform(subPlatform);
        setActiveCategory(activeCategory);
        setActiveType(activeType);
        setMetricData(data);
      })
      .catch((error) => {
        console.error("Error fetching metric fields:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMetricConfig = () => {
    const controller = new AbortController(); // Create an AbortController instance
    const signal = controller.signal; // Get the signal from the controller

    setIsLoading(true);
    if (!activeCategory) return controller;

    const body = {
      sub_media_platform:
        activeSubPlatform || getSubMediaPlatform(activePlatform),
      category: activeCategory,
    };

    axios
      .post(ENDPOINTS.PERFORMANCE_METRIC.getConfig, body, {
        ...apiHeaderConfig,
        signal,
      })
      .then((response) => {
        const data = response.data;
        setLastUpdatedUserDetail(data.updated_by || {});

        const configs = data.config || [];
        const formattedCategoryWiseData = {};

        if (configs.length === 0) {
          setIsEditMode(true);
        }

        configs.forEach((eachConfig = {}) => {
          const {asset_type, configs: nestedConfigs = []} = eachConfig;

          if (asset_type === activeType) {
            nestedConfigs.forEach((nestedConfig) => {
              const {
                id,
                metric_field,
                use_platform_metric,
                is_increasing_score,
                display_order,
                excellent_score,
                good_score,
                low_score,
                average_score,
                updated_by_user,
              } = nestedConfig;

              formattedCategoryWiseData[asset_type] = {
                ...formattedCategoryWiseData[asset_type],
                [metric_field]: {
                  id,
                  is_increasing_score,
                  excellent_score,
                  good_score,
                  low_score,
                  average_score,
                  use_platform_metric,
                  display_order,
                  updated_by_user,
                  is_remove_config: false,
                },
              };
            });
          }
        });
        if (!formattedCategoryWiseData[activeType]) {
          formattedCategoryWiseData[activeType] = {
            "": {
              excellent_score: "",
              good_score: "",
              average_score: "",
              low_score: "",
              use_platform_metric: false,
              updated_by_user: "",
              is_remove_config: false,
            },
          };
        }

        const modifiedFormData = {
          [activePlatform]: {
            [activeSubPlatform]: {
              [activeCategory]: formattedCategoryWiseData,
            },
          },
        };

        setFormData(modifiedFormData);
        initialFormDataRef.current = structuredClone(modifiedFormData);

        const recalculatedValues =
          modifiedFormData[activePlatform]?.[activeSubPlatform]?.[
            activeCategory
          ]?.[activeType] || {};
        setPerformanceFieldValues(recalculatedValues);
        initialPMFValuesRef.current = structuredClone(recalculatedValues);
        setShowSubmitButton(!isObjWithKeys(formattedCategoryWiseData));
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          console.error("Error generating authentication URI:", error);
      });

    return controller; // Return the controller for aborting the request
  };

  const addMetricConfig = (updatedValues) => {
    setIsLoading(true);
    // Format performance metric data for the active type only
    const body = {
      data: formatPerformanceMetricData(
        {[activeType]: updatedValues}, // Pass data for active type only
        activeSubPlatform,
        activeCategory,
        activePlatform
      ),
    };

    axios
      .put(ENDPOINTS.PERFORMANCE_METRIC.addConfig, body, apiHeaderConfig)
      .then((response) => {
        toast.success(response.data.message);
        setIsEditMode(false);
      })
      .catch((error) => {
        handleCancel();
        toast.error(
          capitalize(labelFormatter(error?.response?.data?.error || ""))
        );
      })
      .finally(() => {
        setIsLoading(false);
        getMetricConfig();
      });
  };

  const handleChange = (
    event,
    platform,
    subPlatform,
    category,
    type,
    metric
  ) => {
    const {name, value, checked, type: inputType} = event.target;

    let is_increasing;
    if (name === "metric_field") {
      const metricDetails =
        typeData[activeType]?.metrics?.find(
          (metric) => metric.metric_name === value
        ) || {};
      is_increasing = metricDetails.is_increasing;
    }
    // Convert value to number for specific score fields
    const updatedValue =
      inputType === "text" &&
      ["low_score", "average_score", "good_score", "excellent_score"].includes(
        name
      )
        ? stringToNumber(value) // Utility to convert string to number
        : inputType === "checkbox"
        ? checked
        : value;

    // Construct the path for nested state update
    const path = [
      platform,
      subPlatform,
      category,
      type,
      ...(metric ? [metric] : []),
    ].join(".");

    setFormData((prevState) => {
      const modifiedStateValue = {...prevState};
      const parentPath = [platform, subPlatform, category, type].join(".");
      const parentData = getValue(modifiedStateValue, parentPath) || {};

      if (name === "use_platform_metric") {
        // Reset the metric fields and set use_platform_metric to true
        const updatedParentData = {
          ...parentData,
          default: {
            is_increasing_score: true,
            excellent_score: null,
            good_score: null,
            low_score: null,
            average_score: null,
            use_platform_metric: checked,
            is_remove_config: !checked,
          },
        };

        // Delete any other existing metrics
        for (const key in parentData) {
          if (key !== "default") {
            delete updatedParentData[key]; // Remove other existing metrics
          }
        }

        setValue(modifiedStateValue, parentPath, updatedParentData);
      } else if (name === "metric_field") {
        const metricData = parentData[metric] || {};
        // Create or update the selected metric
        const updatedParentData = {
          ...parentData,
          [value]: {
            ...metricData,
            ...initialMetricScores,
            is_increasing_score: is_increasing,
            is_remove_config: false,
          },
        };
        setValue(modifiedStateValue, parentPath, updatedParentData);
        delete updatedParentData[metric]; // Remove old metric key
      } else {
        const prevStateTypeValue = getValue(prevState, path) || {};
        const valueToSet = {
          ...prevStateTypeValue,
          [name]: updatedValue,
        };
        setValue(modifiedStateValue, path, valueToSet);
      }

      const updatedValues =
        getValue(
          modifiedStateValue,
          `${performanceMetricFieldPath}.${activeType}`
        ) || {};
      setPerformanceFieldValues(updatedValues);
      return modifiedStateValue;
    });
  };

  const handleBlur = (
    event,
    platform,
    subPlatform,
    category,
    type,
    isgraphvisible,
    metric,
    isreverse
  ) => {
    const {name, value} = event.target;

    // Construct the path for nested state update
    const path = [
      platform,
      subPlatform,
      category,
      type,
      ...(metric ? [metric] : []),
    ].join(".");

    setFormData((prevState) => {
      const modifiedStateValue = {...prevState};
      const prevStateTypeValue = getValue(prevState, path) || {};
      const newValue = value.trim() === "" ? 0 : stringToNumber(value);

      if (isgraphvisible) {
        // Validate only the field that triggered the blur event
        const validatedScores = validateGraphScores(
          {...prevStateTypeValue, [name]: newValue},
          name, // Pass the name of the changed score
          isreverse
        );

        setValue(modifiedStateValue, path, {
          ...prevStateTypeValue,
          ...validatedScores,
        });
      } else {
        // Directly update the value without validation
        setValue(modifiedStateValue, path, {
          ...prevStateTypeValue,
          [name]: stringToNumber(value),
        });
      }

      // Update performance field values
      const updatedValues =
        getValue(
          modifiedStateValue,
          `${performanceMetricFieldPath}.${activeType}`
        ) || {};
      setPerformanceFieldValues(updatedValues);

      return modifiedStateValue;
    });
  };

  const handleCancel = (e) => {
    e?.preventDefault();
    setIsEditMode((prevState) => !prevState);
    setFormData(deepCopy(initialFormDataRef.current));
    setPerformanceFieldValues(deepCopy(initialPMFValuesRef.current));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Get the performance field values for the active type
    const performanceValues =
      getValue(formData, performanceMetricFieldPath) || {};
    const initialValues =
      getValue(initialFormDataRef.current, performanceMetricFieldPath) || {};

    const activeValues = performanceValues[activeType];
    let initialActiveValues = initialValues[activeType];

    // Handle empty strings in initialActiveValues
    if (
      initialActiveValues &&
      Object.keys(initialActiveValues).length === 1 &&
      initialActiveValues[""]
    ) {
      initialActiveValues = {};
    }

    // Handle the default metric in initialActiveValues
    if (initialActiveValues?.default) {
      initialActiveValues.default = {
        ...initialActiveValues.default,
        is_remove_config: true,
      };
    }

    // Get changes between initialActiveValues and activeValues
    const updatedValues = getChanges(initialActiveValues, activeValues);

    // Add the modified default metric to updatedValues
    if (initialActiveValues?.default && !updatedValues?.default) {
      updatedValues.default = initialActiveValues.default;
    }

    if (!updatedValues || Object.keys(updatedValues).length === 0) {
      handleCancel();
      toast.warning("No change detected");
      return;
    }

    // Validate scores for the active type only
    const isValid = validateScores(
      updatedValues,
      `${performanceMetricFieldPath}.${activeType}`
    );
    if (!isValid) {
      return;
    }
    addMetricConfig(updatedValues);
  };

  const renderDataNotAvailableMessage = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="p-2">{localisable.noDataAvailable}</div>
      </div>
    );
  };

  const handlePlatformChange = (platformName) => {
    // Reset all states to their initial values
    setActivePlatform(platformName);

    // Fetch metric fields for the new platform
    fetchMetricFields(platformName);

    // Navigate to the new platform route
    navigate(`/api/performance-metric/${platformName}`);
  };

  useEffect(() => {
    fetchMetricFields(activePlatform);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const subPlatformData = metricData[activePlatform] || {};
    const categoryData = subPlatformData[activeSubPlatform] || {};
    const categoryKeys = Object.keys(categoryData);
    const activeCategory =
      categoryKeys.length > 1 ? categoryKeys[1] : categoryKeys[0] || "";
    setActiveCategory(activeCategory);
    const typeData = categoryData[activeCategory] || {};
    setTypeData(typeData);
    const activeType = getValue(Object.keys(typeData), "0") || "";
    setActiveType(activeType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubPlatform]);

  useEffect(() => {
    const controller = getMetricConfig();
    setIsEditMode(false);

    let useDefaultPerformanceMetricExists = false;
    const availableCategories =
      getValue(metricData, performanceMetricFieldPath) || {};
    for (const details of Object.values(availableCategories)) {
      useDefaultPerformanceMetricExists =
        useDefaultPerformanceMetricExists ||
        details.has_default_performance_value;
    }
    setErrors({});
    return () => {
      // Cleanup: cancel the request if it's still in progress
      controller.abort();
    };
    // eslint-disable-next-line
  }, [activeSubPlatform, activeCategory, activeType]);

  useEffect(() => {
    const categoryData =
      metricData[activePlatform]?.[activeSubPlatform]?.[activeCategory] || {};
    setTypeData(categoryData);
    const newActiveType = getValue(Object.keys(categoryData), "0") || "";
    setActiveType(newActiveType);
  }, [activeCategory, activeSubPlatform, metricData, activePlatform]);

  useEffect(() => {
    const categoryData =
      metricData[activePlatform]?.[activeSubPlatform]?.[activeCategory] || {};
    setTypeData(categoryData);
  }, [
    activeType,
    activeCategory,
    activeSubPlatform,
    metricData,
    activePlatform,
  ]);

  return (
    <div className="d-flex flex-column">
      <div className="position-relative text-white pb-2" style={{top: "68px"}}>
        <div className="container-fluid d-flex align-items-center justify-content-center mb-4">
          <div className="col-12 mb-3">
            <div className="detail-container ui-sortable">
              <div className="panel-heading bg-black p-2 rounded-top d-flex justify-content-between">
                <div className="col-5">
                  <h4 className="panel-title mt-1 fs-6">
                    <i className="fa-duotone fa-solid fa-gears me-2"></i>
                    Settings: Performance Metrics
                  </h4>
                </div>
              </div>
              <div className="panel-body rounded-bottom px-3">
                <div className="row my-1">
                  {isLoading ? (
                    <Spinner />
                  ) : isMetricFieldAvailable ? (
                    <PerformanceForm
                      platforms={platforms}
                      handlePlatformChange={handlePlatformChange}
                      platform={activePlatform}
                      metricData={metricData}
                      typeData={typeData}
                      activeSubPlatform={activeSubPlatform}
                      setActiveSubPlatform={setActiveSubPlatform}
                      activeCategory={activeCategory}
                      setActiveCategory={setActiveCategory}
                      activeType={activeType}
                      setActiveType={setActiveType}
                      performanceFieldValues={performanceFieldValues}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      isEditMode={isEditMode}
                      setIsEditMode={setIsEditMode}
                      showSubmitButton={showSubmitButton}
                      isLoading={isLoading}
                      hasError={hasError}
                      handleCancel={handleCancel}
                      lastUpdatedUserDetail={lastUpdatedUserDetail}
                      showcheckbox={
                        typeData[activeType]?.has_default_performance_value
                      }
                      formData={formData}
                      setFormData={setFormData}
                      handleBlur={handleBlur}
                    />
                  ) : (
                    renderDataNotAvailableMessage()
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceMetric;
