// src/components/Card.jsx
import React from "react";
import {Link} from "react-router-dom";
import whiteLabel from "../../../../assets/whitelabelConfig.js";
import {renderBadge} from "../../utils/utils.js";
import CardviewConfig from "./CardviewConfig";
import {formatKeys} from "../../utils/utils.js";

const Cardview = ({asset, handleImageClick, isjourneyMap}) => {
  const insights = asset.insights && typeof asset.insights === 'object' ? asset.insights : {};
  const defaultKeys = Object.keys(insights).slice(0, 6);
  const platformKeys = CardviewConfig[asset.sub_media_platform] || defaultKeys;

  return (
    <>
      <div className="row mb-2">
        {asset.type === "text" ? (
          <div
            className="text-ad"
            title={asset?.title || ''}
            style={{height:"200px", cursor:"pointer"}}
            onClick={() => handleImageClick(asset)}
           >
            {asset.title?.length > 200
              ? `${asset.title.substring(0, 200)}...`
              : asset.title}
          </div>
        ) : (
          <img
            src={
              asset.type === "audio"
                ? (asset.gcs_thumbnail_url || whiteLabel.audio_thumbnail)  
                : asset.gcs_thumbnail_url || whiteLabel.alt_image
            }
            onError={(e) => {
              e.target.src = whiteLabel.alt_image;
            }}
            alt="Advertisement"
            className={isjourneyMap ? "cardview-img" : "img-fluid"}
            // className={"img-fluid"}
            onClick={() => handleImageClick(asset)}
          />
        )}
      </div>
      <div className="text-white">
        <div className="card-body py-2 d-flex align-items-center justify-content-between">
          <h6 className="card-title text-truncate">
            {asset && (
              <>
                {asset.id} {asset.title ? `| ${asset.title}` : " "}
              </>
            )}
          </h6>
          {asset?.performance_value ? (
            renderBadge(asset.performance_value)
          ) : (
            <span
              className="badge ms-1"
              style={{backgroundColor: "#808080", color: "black"}}>
              Unknown
            </span>
          )}
        </div>
        <hr />
        <div className="d-flex justify-content-between card-body-text px-1">
          <div>
            <div className="btn-group dropdown me-2 mb-1">
              <Link
                data-bs-toggle="dropdown"
                className="text-decoration-none text-white">
                Promotional Data <i className="bi bi-caret-down-fill"></i>
              </Link>
              <div className="dropdown-menu dropdown-menu-end text-white">
                <div className="dropdown-item text-white">
                  {asset.media_platform === "Non_Digital" ? (
                    <i className={`bi bi-folder-plus me-2`} />
                  ) : asset.sub_media_platform === "tradedesk" ? (
                    <img
                      src={whiteLabel.TradeDesk_logo}
                      alt={asset.sub_media_platform}
                      className="me-2 mb-1"
                      style={{width:"12px", height:"12px"}}
                    />
                  ): (
                    <i
                      className={`bi bi-${
                        asset.sub_media_platform === "google_ads" ||
                        asset.sub_media_platform === "google_analytics"
                          ? "google"
                          : asset.sub_media_platform
                      } me-2`}></i>
                  )}
                  {
                    asset.sub_media_platform &&
                    formatKeys(asset.sub_media_platform)
                  }
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="btn-group dropdown me-1 mb-1">
              <Link
                data-bs-toggle="dropdown"
                className="text-decoration-none text-white">
                Asset Actions <i className="bi bi-caret-down-fill"></i>
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
              {(asset.type === "blog") || (asset.type === "link") ? (
                  <button
                    className="dropdown-item text-white"
                    disabled = {(asset.type === "blog" && !asset.file_url) || (asset.type === "link" && !asset.external_url) }
                    onClick={() =>
                      window.open(asset.type === "blog" ? asset.file_url: asset.external_url, "_blank")
                    }>
                    {asset.type === "blog" ? "View Blog" : "Visit Link"}
                  </button>
                ) : (
                  <a
                  className={`dropdown-item text-white ${asset.type === "text" || !asset?.gcs_file_url ? "disabled" : ""}`}
                  href={asset.type !== "text" ? `${asset?.gcs_file_url}?response-content-disposition=attachment` : "#"}
                  target="_blank"
                  rel="noreferrer">
                  <i className="fa-solid fa-cloud-arrow-down" aria-hidden="true"></i> Download
                </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row card-body-text d-flex justify-content-center">
          <div className="col-4">
            <div className="row">
              <div className="col-12">Kind:</div>
            </div>
            <div className="row">
              <div className="col-12">
                <i
                  className={`bi ${
                    asset?.type === "video"
                      ? "bi-camera-video-fill"
                      : asset.type === "image"
                      ? "bi-card-image"
                      : asset.type === "pdf"
                      ? "bi-filetype-pdf"
                      : asset.type === "audio"
                      ? "bi bi-volume-up-fill"
                      : asset.type === "webpage"
                      ? "bi bi-filetype-html"
                      : asset.type === "link"
                      ? "bi bi-link-45deg"
                      : "bi bi-file-text-fill"
                  }`}
                />
                <b> {asset?.type}</b>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="row">
              <div>File Size:</div>
            </div>
            <div className="row">
              <div>
                <b>{asset?.file_size}</b>
              </div>
            </div>
          </div>
          <div className="col-4 px-1">
            <div className="row">
              <div>Dimension:</div>
            </div>
            <div className="row">
              <div style={{wordWrap: "break-word"}}>
                <b>{asset?.thumbnail_dimensions} px</b>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <hr />
        <div className="row card-body-text d-flex justify-content-center pb-2">
      <h6>Performance Data</h6>
      {asset?.insights ? (
  asset.category === "Organic" ? (
    <>
      <div className="col-6 pe-0 border-end border-secondary text-break">
        {Object.keys(asset.insights).filter((_, index) => index % 2 === 0).map((key, index) => (
          <div className="mb-2" key={index}>
            <div>
              {formatKeys(key)}:
            </div>
            <div>
              <b>{asset.insights[key]}</b>
            </div>
          </div>
        ))}
      </div>
      <div className="col-6 px-2 text-break">
        {Object.keys(asset.insights).filter((_, index) => index % 2 !== 0).map((key, index) => (
          <div className="mb-2" key={index}>
            <div className="me-2">
              {formatKeys(key)}:
            </div>
            <div>
              <b>{asset.insights[key]}</b>
            </div>
          </div>
        ))}
      </div>
    </>
  ) : (
    <>
      <div className="col-6 pe-0 border-end border-secondary text-break">
        {platformKeys.filter((_, index) => index % 2 === 0).map((key, index) => (
          asset.insights[key] !== undefined && <div className="mb-2" key={index}>
            <div>
              {formatKeys(key)}:
            </div>
            <div>
              <b>{asset.insights[key]}</b>
            </div>
          </div>
        ))}
      </div>
      <div className="col-6 px-2 text-break">
        {platformKeys.filter((_, index) => index % 2 !== 0).map((key, index) => (
          asset.insights[key] !== undefined && <div className="mb-2" key={index}>
            <div className="me-2">
              {formatKeys(key)}:
            </div>
            <div>
              <b>{asset.insights[key]}</b>
            </div>
          </div>
        ))}
      </div>
    </>
  )
) : (
  <div>Unavailable</div>
)}

    </div>
      </div>
    </>
  );
};

export default Cardview;
