import React from "react";

const TabSection = ({
  platforms,
  handlePlatformChange,
  platform,
  whiteLabel,
  metricData,
  activeSubPlatform,
  setActiveSubPlatform,
  activeCategory,
  setActiveCategory,
  activeType,
  setActiveType,
  capitalize,
  labelFormatter,
  showcheckbox,
  use_platform_metric,
  handleChange,
  isEditMode,
  showSubmitButton,
  children,
}) => {
  return (
    <>
      {/* Platforms Tab Section */}
      <ul className="nav nav-pills" role="tablist">
        {platforms.map((platformName) => {
          const isActive = platform === platformName;

          return (
            <li key={platformName} className="nav-item" role="presentation">
              <a
                href={`#nav-pills-tab-${platformName}`}
                className={`nav-link ${isActive ? "active" : "inactive-tab"} ${
                  platformName === "Bing" || platformName === "X"
                    ? "disabled"
                    : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handlePlatformChange(platformName);
                }}
                data-bs-toggle="tab"
                aria-selected={isActive}
                role="tab">
                <span className="d-sm-block d-none">
                  {platformName === "TradeDesk" ? (
                    <img
                      src={whiteLabel.tradedesk_icon}
                      alt="tradedesk_logo"
                      className="me-2 mb-1"
                      style={{height: "20px", width: "20px"}}
                    />
                  ) : (
                    <i
                      className={`fa-brands ${
                        platformName === "X"
                          ? "fa-square-x-twitter"
                          : platformName.toLowerCase() === "bing"
                          ? "fa-windows"
                          : `fa-${platformName.toLowerCase()}`
                      } fa-xl`}
                      aria-hidden="true"
                    />
                  )}{" "}
                  {platformName}
                </span>
              </a>
            </li>
          );
        })}
      </ul>
      {/* Sub-Platform */}
      <div className="tab-content p-0 rounded-0 m-0">
        <ul className="nav nav-tabs border-0" role="tablist">
          {Object.keys(metricData[platform] || {}).map((subPlatform) => (
            <li className="nav-item" role="presentation" key={subPlatform}>
              <a
                href={`#${subPlatform}-tab`}
                data-bs-toggle="tab"
                className={`nav-link ${
                  activeSubPlatform === subPlatform ? "active" : "inactive-tab border-0"
                }`}
                aria-selected={activeSubPlatform === subPlatform}
                role="tab"
                onClick={() => setActiveSubPlatform(subPlatform)}>
                <span className="d-sm-block d-none">
                  {["google_ads", "google_analytics", "tradedesk"].includes(subPlatform) ? (
                    <img
                      src={whiteLabel[`${subPlatform}_icon`]}
                      alt={`${subPlatform}_logo`}
                      className="mb-1 me-1"
                      style={{height: "20px", width: "20px"}}
                    />
                  ) : (
                    <i
                      className={`fa-brands fa-${subPlatform.toLowerCase()} fa-xl me-1`}
                      aria-hidden="true"></i>
                  )}
                  {capitalize(labelFormatter(subPlatform), true)}
                </span>
              </a>
            </li>
          ))}
        </ul>

        {/* Category Tabs */}
        <div
          className={`tab-content panel rounded-0 p-2 m-0 ${
            activeCategory ? "active-shaded" : ""
          }`}>
          <ul className="nav nav-tabs border-0" role="tablist">
            {(() => {
              const categories = Object.keys(
                metricData[platform]?.[activeSubPlatform] || {}
              );

              // If there are exactly 2 elements, reorder them
              if (categories.length === 2) {
                [categories[0], categories[1]] = [categories[1], categories[0]];
              }

              return categories.map((category) => (
                <li className="nav-item" role="presentation" key={category}>
                  <a
                    href={`#category-tab-${category}`}
                    data-bs-toggle="tab"
                    className={`nav-link ${
                      activeCategory === category
                        ? "active"
                        : "inactive-tab border-0"
                    }`}
                    aria-selected={activeCategory === category}
                    role="tab"
                    onClick={() => setActiveCategory(category)}>
                    <span className="d-sm-block d-none">
                      <i
                        className={`fa-duotone fa-solid fa-${
                          category === "Organic" ? "seedling" : "dollar-sign"
                        } fa-xl me-1`}
                        aria-hidden="true"></i>
                      {capitalize(labelFormatter(category), true)}
                    </span>
                  </a>
                </li>
              ));
            })()}
          </ul>

          {/* Nested Category and Type Tabs */}
          {Object.keys(metricData[platform]?.[activeSubPlatform] || {}).map(
            (category) => (
              <div
                className={`tab-pane fade rounded-0 m-0 p-2 active-shaded ${
                  activeCategory === category ? "active show" : "inactive-tab"
                }`}
                id={`category-tab-${category}`}
                role="tabpanel"
                key={category}>
                <ul className="nav nav-tabs border-0" role="tablist">
                  {Object.keys(
                    metricData[platform]?.[activeSubPlatform]?.[category] || {}
                  ).map((type) => (
                    <li className="nav-item" role="presentation" key={type}>
                      <a
                        href={`#type-tab-${category}-${type}`}
                        data-bs-toggle="tab"
                        className={`nav-link ${
                          activeType === type
                            ? "active bg-primary"
                            : "inactive-tab border-0"
                        }`}
                        aria-selected={activeType === type}
                        role="tab"
                        onClick={() => setActiveType(type)}>
                        <span className="d-sm-block d-none">
                          <i
                            className={`fa-duotone fa-solid fa-${
                              type === "text"
                                ? "font"
                                : type === "album"
                                ? "images"
                                : type === "event"
                                ? "calendar"
                                : type
                            } fa-xl me-1`}
                            aria-hidden="true"></i>
                          {capitalize(labelFormatter(type), true)}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>

                {/* Render Content for Active Type */}
                {Object.keys(
                  metricData[platform]?.[activeSubPlatform]?.[category] || {}
                ).map(
                  (type) =>
                    activeType === type && (
                      <div
                        className="tab-content panel rounded-0 p-2 m-0 active-shaded"
                        id={`type-tab-${category}-${type}`}
                        role="tabpanel"
                        key={type}>
                        {showcheckbox && (
                          <div className="d-flex align-items-center gap-2 mb-1">
                            <input
                              className="form-check-input mb-1"
                              type="checkbox"
                              name="use_platform_metric"
                              id="use_platform_metric"
                              checked={use_platform_metric}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  platform,
                                  activeSubPlatform,
                                  activeCategory,
                                  activeType
                                )
                              }
                              disabled={!isEditMode && !showSubmitButton}
                            />
                            <label
                              htmlFor="use_platform_metric"
                              className="form-check-label mb-0">
                              Use default Platform Metric
                            </label>
                          </div>
                        )}
                        {children}
                      </div>
                    )
                )}
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default TabSection;
