import React, {useEffect, useRef, useState, useMemo} from "react";
import * as d3 from "d3";
import { createLegend } from "./Legend";
import { createPercentileSliders } from "./Sliders";
import { calculateScoreRanges, handleDrag, generateBellCurve } from "./utils";
import {labelFormatter} from "../../../../../common/helpers/utils/Formatter";

const BellCurve = ({
  data,
  kpi,
  formData,
  setFormData,
  platform,
  activeSubPlatform,
  activeCategory,
  activeType,
  metric_name,
  isEditMode,
  isreverse,
  updateScores,
  getCounts,
  showSpinner,
  pointersLoading,
}) => {
  const svgRef = useRef(null);
  const currentdata = useMemo(() => {
    return (
      formData[platform]?.[activeSubPlatform]?.[activeCategory]?.[activeType]?.[
        metric_name
      ] || {}
    );
  }, [
    formData,
    platform,
    activeSubPlatform,
    activeCategory,
    activeType,
    metric_name,
  ]);

  const [percentiles, setPercentiles] = useState({
    excellent_score: Number(currentdata.excellent_score) || 0,
    good_score: Number(currentdata.good_score) || 0,
    average_score: Number(currentdata.average_score) || 0,
    low_score: Number(currentdata.low_score) || 0,
  });

  useEffect(() => {
    if (currentdata) {
      setPercentiles({
        excellent_score: Number(currentdata.excellent_score),
        good_score: Number(currentdata.good_score),
        average_score: Number(currentdata.average_score),
        low_score: Number(currentdata.low_score),
      });
    }
  }, [currentdata]); // Trigger whenever currentdata changes

  // Define percentile lines
  const percentileLines = [
    {key: "excellent_score", color: "#00a318", label: "excellent_score"},
    {key: "good_score", color: "#b2ff6b", label: "good_score"},
    {key: "average_score", color: "#FFFF00", label: "average_score"},
    {key: "low_score", color: "#ff6344", label: "low_score"},
  ];

  useEffect(() => {
    const bins = data.histogram;
    const width = 800;
    const height = 400;
    const margin = {
      top: 10,
      right: isreverse ? 80 : 145,
      bottom: 80,
      left: isreverse ? 145 : 80,
    };

    const maxValue = Math.max(
      d3.max(bins, (d) => d.p2), // Maximum of p2 in bins
      ...Object.values(percentiles) // Maximum of percentiles
    );

    const tenPercentMaxValue = maxValue * 1.1; // Calculate 10% of the maximum value

    // Define scales
    const x = d3
      .scaleLinear()
      .domain([
        Math.min(
          0,
          d3.min(bins, (d) => d.p1)
        ),
        Math.max(tenPercentMaxValue, maxValue),
      ])
      .range(
        isreverse
          ? [width - margin.right, margin.left]
          : [margin.left, width - margin.right]
      );

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(bins, (d) => d.count)])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);

    svg.selectAll("*").remove(); // Clear existing elements before rendering

    // Render histogram bars
    svg
      .append("g")
      .attr("fill", "silver")
      .attr("opacity", 0.5)
      .selectAll("rect")
      .data(bins)
      .enter()
      .append("rect")
      .attr("x", (d) => Math.min(x(d.p1), x(d.p2)) + 1) // Start at the smaller x position
      .attr("width", (d) => Math.max(0.1, Math.abs(x(d.p2) - x(d.p1)) - 1))
      .attr("y", (d) => y(d.count))
      .attr("height", (d) => y(0) - y(d.count));

    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickFormat((d) =>
            d >= 1_000_000 ? d3.format(".2s")(d).replace("G", "B") : d
          )
          .tickSizeOuter(0)
      )
      .call((g) => g.selectAll(".domain, .tick line").attr("stroke-width", 2));

    svg
      .append("g")
      .attr(
        "transform",
        isreverse
          ? `translate(${width - margin.right},0)`
          : `translate(${margin.left},0)`
      )
      .call(isreverse ? d3.axisRight(y) : d3.axisLeft(y))
      .call((g) => g.selectAll(".domain, .tick line").attr("stroke-width", 2));

    // Add X-axis label
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("x", (width + margin.left - margin.right) / 2)
      .attr("y", height - margin.bottom / 1.75)
      .style("font-size", "16px")
      .style("font-weight", "bold")
      .attr("fill", "white")
      .text(labelFormatter(kpi));

    // Add Y-axis label
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("transform", `rotate(-90)`)
      .attr("x", -(height + margin.top - margin.bottom) / 2) // Center along Y-axis
      .attr(
        "y",
        isreverse ? width - margin.right / 2 + 30 : margin.left / 3 - 10
      )
      .style("font-size", "16px")
      .style("font-weight", "bold")
      .attr("fill", "white")
      .text("Number of Assets");

    // Generate and render bell curve
    const bellCurve = generateBellCurve(data.kde_data);

    // Calculate the maximum height of the histogram bins
    const maxHistogramHeight = d3.max(bins, (d) => d.count);

    // Scale the KDE values to match the histogram's height
    const scaledBellCurve = bellCurve.map((point) => ({
      x: point.x,
      y: point.y * (maxHistogramHeight / d3.max(bellCurve, (d) => d.y)), // Normalize KDE y-values
    }));

    // Define the line generator for the bell curve
    const line = d3
      .line()
      .x((d) => x(d.x)) // Use the x-scale for positioning
      .y((d) => y(d.y)); // Use the y-scale for the scaled bell curve

    // Append the scaled bell curve as a path to the SVG
    svg
      .append("path")
      .datum(scaledBellCurve) // Use the scaled bell curve data
      .attr("fill", "none")
      .attr("stroke", "white")
      .attr("stroke-width", 3)
      .attr("d", line); // Generate the path using the line generator

    createLegend(svg, data, width, margin, isreverse, showSpinner, pointersLoading);

    // Add background regions for the score ranges
    const scoreRanges = calculateScoreRanges(percentiles, isreverse, x)

    // Shade regions based on the score ranges
    scoreRanges.forEach((range) => {
      // Filter the bell curve points that fall within or near the current range
      const curveSegment = scaledBellCurve.filter(
        (point) => point.x >= range.min - 0.001 && point.x <= range.max + 0.001
      );

      if (curveSegment.length > 0) {
        // Add baseline points (x, 0) at the boundaries for a seamless area
        const areaPoints = [
          {x: range.min, y: 0},
          ...curveSegment,
          {x: range.max, y: 0},
        ];

        // Create an area generator for smoother curves
        const areaGenerator = d3
          .area()
          .x((d) => x(d.x)) // Map x to the scaled x-axis
          .y0(y(0)) // Baseline at y = 0
          .y1((d) => y(d.y)) // Curve height
          .curve(d3.curveMonotoneX); // Smooth and avoid overlaps

        // Append the area to the SVG
        svg
          .append("path")
          .datum(areaPoints)
          .attr("fill", range.color)
          .attr("opacity", 0.9)
          .attr("d", areaGenerator);
      }
    });

    // Add percentile lines with draggable handles
    createPercentileSliders(
      svg,
      percentileLines,
      percentiles,
      x,
      width,
      height,
      margin,
      isreverse,
      handleDrag,
      getCounts,
      setPercentiles,
      formData,
      setFormData,
      platform,
      activeSubPlatform,
      activeCategory,
      activeType,
      metric_name,
      updateScores
    );

    // eslint-disable-next-line
  }, [percentiles, data, formData]);

  return (
    <svg
      ref={svgRef}
      style={{
        pointerEvents: isEditMode ? "auto" : "none", // Disable interactions when isEditMode is false
      }}></svg>
  );
};

export default BellCurve;
