import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
import { Provider } from "react-redux";
import store from "./redux/store";

import AuthWrapper from "./AuthWrapper";
import ErrorBoundaries from "./common/components/generic/errorBoundaries/ErrorBoundaries";

function App() {
  return (
    <Provider store={store}>
      <Router>
      <ToastContainer position="top-right" autoClose={5000} style={{ zIndex: 2000 }} />
        <ErrorBoundaries>
          <AuthWrapper/>
        </ErrorBoundaries>
      </Router>
    </Provider>
  );
}

export default App;
